@media only screen and (min-width: 768px) and (max-width: 992px) {
  .life-at-eapro-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img {
    margin-bottom: 10px;
    position: relative;
    text-align: center;
  }
  .life-at-eapro-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .life-at-eapro-slider-section .slick-next {
    background: url("../../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -20px;
  }
  .life-at-eapro-slider-section .slick-next::before {
    display: none;
  }
  .life-at-eapro-slider-section .slick-slider .slick-slide {
    transition: all 0.5s ease-in-out;
  }
  .life-at-eapro-slider-section .slick-prev {
    background: url("../../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -20px;
  }
  .life-at-eapro-slider-section .slick-prev::before {
    display: none;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .life-at-eapro-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img {
    margin-bottom: 10px;
    position: relative;
    text-align: center;
  }
  .life-at-eapro-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .life-at-eapro-slider-section .slick-next {
    background: url("../../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: 0px;
  }
  .life-at-eapro-slider-section .slick-next::before {
    display: none;
  }
  .life-at-eapro-slider-section .slick-slider .slick-slide {
    transition: all 0.5s ease-in-out;
  }
  .life-at-eapro-slider-section .slick-prev {
    background: url("../../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: 0px;
    z-index: 99;
  }
  .life-at-eapro-slider-section .slick-prev::before {
    display: none;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .life-at-eapro-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img {
    margin-bottom: 10px;
    position: relative;
    text-align: center;
  }
  .life-at-eapro-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .life-at-eapro-slider-section .slick-next {
    background: url("../../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -20px;
  }
  .life-at-eapro-slider-section .slick-next::before {
    display: none;
  }
  .life-at-eapro-slider-section .slick-slider .slick-slide {
    transition: all 0.5s ease-in-out;
  }
  .life-at-eapro-slider-section .slick-prev {
    background: url("../../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -20px;
  }
  .life-at-eapro-slider-section .slick-prev::before {
    display: none;
  }
}/*# sourceMappingURL=ResponsiveLifeAteaproSlider.css.map */