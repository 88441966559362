.know-more-about-power-section {
    background: #cccccc5c;
    background-image: url("../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .heading-dv {
        h3 {
            font-weight: 700;
            font-size: 22px;
        }
    }

    .slick-next {
        background: url('../../../../public/images/right.png')center center no-repeat !important;
        width: 40px;
        height: 65px;

        &::before {
            display: none;
        }
    }

    .slick-prev {
        background: url('../../../../public/images/left.png')center center no-repeat !important;
        width: 40px;
        height: 65px;

        &::before {
            display: none;
        }
    }

    .slick-slider {
        width: 100%;
        background-color: transparent;

        .slick-slide {
            margin: 30px 0px;
            padding: 20px 50px;
            transition: all 0.5s ease-in-out;

            &.slick-current,
            &.slick-center {
                .slider-item-dv {
                    background: #FFC300;
                    border-radius: 5px;
                }

            }
        }
    }

    .slick-slider .slick-slide.slick-current,
    .slick-slider .slick-slide.slick-center {
        transform: scale(1.05);
        padding: 0px 15px;
    }

    .slider-parent-dv {
        padding: 50px 60px;
        height: 380px;
        .slider-item-dv {
            border: 1px solid #ccc;
            background-color: #fff;
            border-radius: 5px;

            .slider-item-img {
                
            }

            .slider-item-heading {
                h4 {
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }
}