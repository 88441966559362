.service-center-locator-content-section {
  background: #000;
  background-image: url("../../../../public/images/store-locator/color-bg.jpg");
  background-position: center;
  background-size: cover;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.service-center-locator-content-section .see-more-btn {
  margin: 20px 0px;
}
.service-center-locator-content-section .see-more-btn button {
  padding: 7px 20px;
  background-color: #000;
  color: #FFC300;
  text-decoration: none;
  border-radius: 5px;
}
.service-center-locator-content-section .store-font {
  font-size: small;
}
.service-center-locator-content-section .store-fs-large {
  font-size: large;
}

.service-center-locator-content-section-card .store-locator-card {
  background-color: #F1F0F0;
  text-align: left;
  padding: 15px 10px;
  border-radius: 10px;
  margin: 5px 0px;
}
.service-center-locator-content-section-card .store-locator-card h4 {
  color: #FFC300;
  margin-bottom: 0px;
}
.service-center-locator-content-section-card .store-locator-card .para-store-add p {
  margin-bottom: 0px;
}
.service-center-locator-content-section-card .store-locator-card .phone-icon img {
  width: 20px;
}/*# sourceMappingURL=ServiceCenterLocatorPageContent.css.map */