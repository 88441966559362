.banner-section {
  margin-bottom: 60px;

  .carousel {

    .carousel-control-prev {
      width: 5% !important
    }

    .carousel-control-next {
      width: 5% !important
    }

    .carousel-control-prev-icon {
      // margin-right: 70%;
    }

    .carousel-control-next-icon {
      // margin-left: 70%;
    }

    .carousel-indicators {
      margin-bottom: -35px;


      input {
        width: 12px;
        height: 12px;
        // background-color: #FFC300;
        border-radius: 50%;
        border: 1px solid #ccc;
      }

      input.active {
        background-color: #ffc300 !important;
        opacity: 1;
      }
    }

    .carousel-inner {
      text-align: center;
      margin: auto;

      .carousel-item {
        height: auto;
        // width: unset;
        // margin: auto;
        // float: unset;
        // text-align: center;

        video {
          width: 100%; // Ensure full width
          height: auto; // Maintain aspect ratio
          object-fit: cover; // Ensure proper scaling
        }

        img {
          width: 100%; // Ensure full width
          height: auto; // Maintain aspect ratio

        }

        .carousel-caption {
          position: absolute;
          top: 50%;
          bottom: 0px;
          transform: translate(0%, -50%);
          left: 5%;
          width: 50%;
          text-align: left;
          height: 70%;

          .carousel-width {
            width: 100%;
          }

          .banner-heading {
            font-size: 40px;
            font-family: "Orbitron", sans-serif;
            line-height: 45px;
          }

          .para-name {
            font-size: 15px;
            font-style: italic;
            width: 100%;

            span {
              color: #ffc300;
            }
          }

          .learn-more-btn {
            position: absolute;
            bottom: 0%;

            a {
              background-color: #ffc300;
              font-size: 16px;
              font-weight: 700;
              text-decoration: none;
              color: #000;
              padding: 8px 15px;
              border-radius: 10px;

              i {
                margin-right: 6px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}