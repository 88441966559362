.meet-our-team-section {
    background: #cccccc5c;
    background-image: url("../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .our-team-parent-dv {
        padding: 50px 60px;
        height: 380px;
    }

    .our-team-img {
        width: 330px;
        height: max-content;
        border-radius: 50%;
        object-fit: fill;


    }

    .teamimg {
        max-width: 210px;
        height: 175px;
        border-radius: 50%;
    }



    .our-team-dv {
        background: #fff;
        border: 1px solid grey;
        border-radius: 5px;
    }

    .our-team-heading {
        h4 {
            font-size: 16px;
            font-weight: 700;
        }

        p {
            margin-bottom: 0px;
        }
    }

    .heading-dv {
        h5 {
            font-weight: 700;
            font-size: 22px;
        }
    }

    .slick-slide img {

        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .slick-next {
        background: url('../../../../public/images/right.png')center center no-repeat !important;
        width: 40px;
        height: 65px;

        &::before {
            display: none;
        }
    }

    .slick-prev {
        background: url('../../../../public/images/left.png')center center no-repeat !important;
        width: 40px;
        height: 65px;

        &::before {
            display: none;
        }
    }

    .slick-slider {
        width: 100%;
        background-color: transparent;

        .slick-slide {
            margin: 30px 0px;
            padding: 20px 50px;
            transition: all 0.5s ease-in-out;

            &.slick-current,
            &.slick-center {
                .slider-item-dv {
                    background: #fff;
                    border-radius: 5px;

                    .slider-item-img {
                        text-align: center;
                    }
                }

            }
        }
    }

    .slick-slider .slick-slide.slick-current,
    .slick-slider .slick-slide.slick-center {
        transform: scale(1.05);
        padding: 23px 15px;
    }

    .slider-parent-dv {
        padding: 50px 60px;
        height: 380px;

        .slider-item-dv {
            border: 1px solid #ccc;
            background-color: #fff;
            border-radius: 5px;
            padding: 23px;

            .slider-item-img {
                display: flex;
                flex-direction: row;
                align-content: flex-start;
                justify-content: center;
                align-items: center;
            }

            .slider-item-heading {
                h4 {
                    font-size: 16px;
                    font-weight: 700;
                }

                p {
                    margin-bottom: 0px;
                }
            }
        }
    }




    .founder-md-section-parent-new-design-our-team {
        // background: #000;
        // background-image: url("../../../../public/images/about-us/ab-pattern-bg.png");
        background-position: center;
        background-size: cover;
        // background-color: #F1F0F0;
        background-repeat: no-repeat;
        padding: 50px 0px;

        .founder-images-left-dv {
            background: #fff;
            padding: 15px;
            // border: 1px solid #FDBF05;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            img {
                width: 237px;
                height: 149px;
                object-fit: contain;
                border-radius: 50%;
            }
        }

        .founder-images-right-dv {
            background: #fff;
            padding: 15px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            // border: 1px solid #FDBF05;

            img {
                width: 237px;
                height: 149px;
                object-fit: contain;
                border-radius: 50%;
            }
        }

        .founder-name-post {
            a {
                text-decoration: none;
                color: #000;
            }

            margin: 10px 0px;

            p {
                font-size: 14px;
            }
        }
    }
}