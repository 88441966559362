.career-at-eapro-three-con-search-jobs {
  background-color: #f1f0f0;
  padding: 40px;
  margin-top: 40px;
}
.career-at-eapro-three-con-search-jobs .search-job-btn {
  margin: 30px 0px 10px 0px;
}
.career-at-eapro-three-con-search-jobs .search-job-btn button {
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.career-at-eapro-three-page-content-section {
  background: #000;
  background-image: url("../../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination {
  /* Styles for the previous and next buttons */
  /* Styles for active page number */
  /* Styles for other page numbers */
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-item {
  color: #000;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-item .page-link {
  border: none;
  background-color: transparent;
  color: #000;
  font-weight: 700;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-item .page-link svg {
  font-weight: bold;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-item.active .page-link {
  border: none;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .previous {
  background: url("/public/left-arrow.png");
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .next {
  background: url("/public/right-arrow.png");
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .previous,
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .next {
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 5px;
  border-radius: 4px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  background-size: contain;
  width: 2px;
  background-repeat: no-repeat;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .previous:hover,
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .next:hover {
  background-color: #e0e0e0;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-num.active {
  font-weight: bold;
  color: blue;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-num {
  display: inline-block;
  padding: 5px 8px;
  margin: 0 2px;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .pagination-dv nav .pagination .page-num:hover {
  background-color: #f0f0f0;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .table-responsive .table {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .table-responsive .table tbody tr td a {
  text-decoration: none;
  color: #000;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .upload-btn-para-bottom {
  text-align: center;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .upload-btn-para-bottom .unable-to-find {
  font-size: 17px;
}
.career-at-eapro-three-page-content-section .listing-parent-dv .upload-btn-para-bottom .upload-your-dv a {
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  text-decoration: none;
  border-radius: 5px;
}
.career-at-eapro-three-page-content-section .job-apply-btn {
  background-color: #000;
  color: #fff !important;
  padding: 6px 18px;
  border-radius: 5px;
  text-decoration: none;
}/*# sourceMappingURL=CareerAtEaproThreeContent.css.map */