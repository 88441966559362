.load-calculator {
    .form-ui {
        background-color: white;
        -webkit-box-shadow: -1px 1px 5px 5px rgba(133, 128, 128, 0.75);
        -moz-box-shadow: -1px 1px 5px 5px rgba(133, 128, 128, 0.75);
        box-shadow: -1px 1px 5px 5px rgba(133, 128, 128, 0.75);
        margin: 30px 0px;

        .heading {
            .count-img {
                width: 57px;
                height: 57px;
                background-size: contain;
            }

            span {
                font-size: large;
            }
        }

        .accordion-outer-box {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .accordion-button {
            background-color: #212529;
            color: white;

            &::after {
                color: white;
            }
        }

        .accordion-body-box {
            background-color: white;

            .accordion-body {
                overflow-x: scroll;
                // min-width: 320px;
            }

            .accordion-body::-webkit-scrollbar {
                width: 5px;
                background-color: transparent;
            }

            .accordion-body::-webkit-scrollbar-thumb {
                background-color: #fff;
            }

            .accordion-body::-webkit-scrollbar-track {
                border: none;
                background-color: transparent;
            }
        }

        .accordion-button {
            &::after {
                content: url("/public/images/minus-sign.png");
                width: 1.25rem;
                height: 1.25rem;
            }

            &.collapsed::after {
                content: url("/public/images/plus-sign.png"); // You can change this to another image for the collapsed state
            }
        }
    }
}