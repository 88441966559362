.over-global-energy-con-page-content-section {
  background: #000;
  background-image: url('../../../../public/images/help-and-support/color-bg.jpg');
  background-position: center;
  background-size: 100% 100%;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;

  .global-energy-heading {
    font-weight: 700;
  }

  .top-para {
    margin: 30px 0px;
  }

  .icon-row-parent {
    .icon-heading-parent {
      .icon-heading-card {
        position: relative;

        &:after {
          width: 2px;
          height: 100px;
          content: '';
          position: absolute;
          margin-top: 5px;
          right: 0;
          top: 0px;
          margin-left: -50px;
          background-color: #000;
        }

        img {
          width: 132px;
          height: 100px;
        }

        h4 {
          font-size: 18px;
          font-weight: 700;
          margin: 15px 0px;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          padding-bottom: 5px;

          span {
            color: #f0ad00;
          }

          &:before {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: block;
            content: '';
            position: absolute;
            bottom: 1px;
            border: 3px solid #ffe;
            left: 50%;
            transform: translate(-50%, -0%);
            margin-left: 0;
            background-color: #f0ad00;
            z-index: 9;
          }

          &:after {
            width: 100px;
            height: 2px;
            display: block;
            content: '';
            position: relative;
            margin-top: 5px;
            left: 50%;
            margin-left: -50px;
            background-color: #000;
          }
        }
      }

      .last-icon-heading-card {
        &:after {
          width: 2px;
          height: 100px;
          content: '';
          position: absolute;
          margin-top: 5px;
          right: 0;
          top: 0px;
          margin-left: -50px;
          background-color: transparent;
        }
      }
      .dark-icons-content {
        position: relative;
        padding: 10px 15px;
        &:after {
          width: 2px;
          height: 200px;
          content: '';
          position: absolute;
          margin-top: 5px;
          right: 0;
          top: 0px;
          margin-left: -50px;
          background-color: #000;
        }
        p {
          margin: 20px 0px;
          height: 100px;
          overflow-y: scroll;
          overflow-x: hidden;
          &::-webkit-scrollbar-track {
            border: none;
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 5px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #000000;
          }
        }
      }
      .last-dark-icons-content {
        &:after {
          width: 2px;
          height: 100px;
          content: '';
          position: absolute;
          margin-top: 5px;
          right: 0;
          top: 0px;
          margin-left: -50px;
          background-color: transparent;
        }
      }
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .over-global-energy-con-page-content-section {
    .icon-row-parent {
      .icon-heading-parent {
        .icon-heading-card {
          &:after {
            display: none;
          }
        }
        .dark-icons-content {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
