.achievements-component-section {
  background: #000;
  background-image: url('../../../../public/images/about-us/ab-pattern-bg.png');
  background-position: center;
  background-size: cover;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  .achievements-component-heading-dv {
    padding-bottom: 30px;
    h5 {
      font-weight: 700;
      font-size: 22px;
    }
  }
  .achievements-award-img-left {
  }
  .achievement-award-right-dv-content {
    text-align: left;
    ul {
      li {
        margin-bottom: 20px;
      }
    }
  }
  .achievement-award-left-dv-content {
    text-align: left;
    ul {
      li {
        margin-bottom: 20px;
      }
    }
  }
}
