.business-division-content-section {
    background: #000;
    background-image: url("../../../../public/images/business-division/color-bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;
    background-attachment: fixed;
    .business-division-content-heading-dv{
        h5{
            font-weight: 700;
            font-size: 22px;
        }
    }
    .dv-left-right-division-content-row{
        margin: 20px 0px 50px 0px ;
        .dv-left-content{
            p{
                text-align: left;
            }
        }
        .dv-right-image{}
        .dv-right-content{
            p{
                text-align: left;
            }
        }
    }
}