.about-banner-parent-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/about-us/about-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.about-banner-parent-section .specialization-heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.about-banner-parent-section .years-of-trust-content {
  text-align: left;
}
.about-banner-parent-section .years-of-trust-content h1 {
  font-size: 35px;
  font-weight: 700;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .about-banner-parent-section .years-of-trust-content {
    text-align: justify;
  }
  .about-banner-parent-section .years-of-trust-content h1 {
    font-size: 20px;
    font-weight: 700;
  }
}/*# sourceMappingURL=AboutBannerCompo.css.map */