.blog-banner-section {
  background: #000;
  background-position: center;
  background-size: cover;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
}
.blog-banner-section .business-heading {
  position: relative;
  text-align: center;
}
.blog-banner-section .business-heading img {
  height: 100px;
  width: 50%;
}
.blog-banner-section .business-heading .business-banner-heading {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  width: 100%;
}
.blog-banner-section .business-heading .business-banner-heading h1 {
  font-weight: 700;
  font-size: 40px;
}
.blog-banner-section .business-heading .business-banner-heading h5 {
  font-weight: 700;
  width: 45%;
}
.blog-banner-section .banner-top-img-bg-rel {
  position: relative;
}
.blog-banner-section .banner-top-img-bg-rel img.banner-img-tag {
  width: 100%;
}
.blog-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
  position: absolute;
  top: 80%;
  left: 0%;
  transform: translate(0%, -80%);
  width: 100%;
}/*# sourceMappingURL=BlogDetailsBanner.css.map */