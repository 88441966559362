.purchase-amc-page-3-content-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.purchase-amc-page-3-content-section .purchase-amc-product-details-parent {
  text-align: left;
}
.purchase-amc-page-3-content-section .purchase-amc-product-details-parent .product-details-heading p {
  font-weight: 700;
}
.purchase-amc-page-3-content-section .purchase-amc-product-details-parent table tbody tr td {
  font-size: 15px;
  padding: 3px;
}
.purchase-amc-page-3-content-section .help-support-heading h5 {
  font-weight: 700;
}
.purchase-amc-page-3-content-section .select-plan-start-row {
  margin-top: 70px;
  margin-bottom: 20px;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 365px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 58%, rgb(204, 204, 204) 100%);
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-inner-name-img {
  position: relative;
  top: -27px;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-inner-name-img .plan-inner-heading {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-inner-name-img .plan-inner-heading p {
  font-weight: 700;
  font-size: 22px;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-price-div h1 {
  color: #FBC306;
  margin-bottom: 0px;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-price-duration {
  margin: 30px 0px;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-price-duration p {
  color: #757474;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-buy-now-btn {
  margin: 20px 0px;
}
.purchase-amc-page-3-content-section .select-plan-start-row .select-you-plan-parent .plan-buy-now-btn a {
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  text-decoration: none;
}
.purchase-amc-page-3-content-section .help-support-content-dv {
  margin: 20px 0px;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.purchase-amc-page-3-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}

.help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}/*# sourceMappingURL=PurchaseAMCPage3Content.css.map */