.about-export-section {
    background: #cccccc5c;
    background-image: url("../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .why-heading-dv {
        h5 {
            font-weight: 700;
            font-size: 22px;
        }
    }

    .para-content {
        p{
            text-align: justify;
        }
    }

    .counter-row {
        .card-box-counter {
            background-color: #000;
            padding: 30px 20px;
            color: #fff;

            h4 {
                margin-bottom: 0px;
                font-weight: 700;
            }

            p {
                margin-bottom: 0px;
                font-size: 15px;
            }
        }

        .card-box-counter.bg-yellow {
            background-color: #FFC300;
            color: #000;
        }
    }
}