.installation-help-page-content-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px 0px 0px;
  background-attachment: fixed;
  /* Checkout Css */
}
.installation-help-page-content-section .modal-dialog {
  box-shadow: 0px 0px 5.5px 5px rgba(0, 0, 0, 0.2509803922);
  overflow: hidden;
}
.installation-help-page-content-section .order-place h3 {
  color: #08AAE3;
}
.installation-help-page-content-section .order-image img {
  height: 320px;
}
.installation-help-page-content-section .thank-you button {
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  color: #fff;
  background: linear-gradient(92.83deg, #08AAE3 -74.66%, #FF9811 343.14%);
}
.installation-help-page-content-section .modal {
  margin-top: 100px;
}
.installation-help-page-content-section .modal-dialog {
  box-shadow: 0px 0px 5.5px 5px rgba(0, 0, 0, 0.2509803922);
  overflow: hidden;
}
.installation-help-page-content-section .help-support-heading h5 {
  font-weight: 700;
}
.installation-help-page-content-section .help-support-content-dv {
  margin: 20px 0px;
}
.installation-help-page-content-section .form-parent-custon-dv {
  text-align: left;
  border-radius: 16px;
  background: #F1F0F0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 50px;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form {
  text-align: left;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .label-background {
  background: rgba(68, 68, 68, 0.1019607843);
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form input::-moz-placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form input::placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form select {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-front {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-front .upload-product-icon {
  position: absolute;
  right: 6px;
  top: 4px;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-front .upload-product-icon img {
  width: 25px;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-front span {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-back {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-back .upload-product-icon {
  position: absolute;
  right: 6px;
  top: 4px;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-back .upload-product-icon img {
  width: 25px;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .upload-product-back span {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .submit-btn-custom {
  background-color: #000;
  color: #FFBC00;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .react-international-phone-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
}
.installation-help-page-content-section .form-parent-custon-dv .custom-row-form .react-international-phone-country-selector-button {
  border: none;
}
.installation-help-page-content-section .help-and-support-section-inner {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/help-support-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-color: transparent;
}
.installation-help-page-content-section .help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.installation-help-page-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}

.help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}/*# sourceMappingURL=InstallationHelpPageContent.css.map */