@media only screen and (min-width: 768px) and (max-width: 992px) {
  .solar-solution-page-banner-section .business-heading {
    position: relative;
    text-align: center;
  }
  .solar-solution-page-banner-section .business-heading img {
    height: 150px;
    width: 50%;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading h1 {
    font-weight: 700;
    font-size: 28px;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 20px;
    width: 100%;
  }
  .solar-solution-page-banner-section .banner-top-img-bg-rel {
    position: relative;
  }
  .solar-solution-page-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .solar-solution-page-banner-section .business-heading {
    position: relative;
    text-align: center;
  }
  .solar-solution-page-banner-section .business-heading img {
    height: 100px;
    width: 50%;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading h1 {
    font-weight: 700;
    font-size: 20px;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 16px;
    width: 50%;
  }
  .solar-solution-page-banner-section .banner-top-img-bg-rel {
    position: relative;
  }
  .solar-solution-page-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .solar-solution-page-banner-section .business-heading {
    position: relative;
    text-align: center;
  }
  .solar-solution-page-banner-section .business-heading img {
    height: 100px;
    width: 50%;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading h1 {
    font-weight: 700;
    font-size: 16px;
  }
  .solar-solution-page-banner-section .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 14px;
    width: 100%;
  }
  .solar-solution-page-banner-section .banner-top-img-bg-rel {
    position: relative;
  }
  .solar-solution-page-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
}/*# sourceMappingURL=ResponsiveSolarSolutionPageBanner.css.map */