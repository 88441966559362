

.business-division-banner-section {
    background: #000;
    // background-image: url("../../../../public/images/help-and-support/banner-bg.jpg");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    .business-heading-top{
        h1{
            font-weight: 700;
            font-size: 60px;
        }
    }
    .business-heading {
        position: relative;
        text-align: center;

        img {
            height: 60px;
        }

        h1 {
            font-weight: 700;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 55px;

        }
    }
  

    .banner-top-img-bg-rel {
        position: relative;

        img.banner-img-tag {
            width: 100%;
        }

        .banner-top-content-absol {
            position: absolute;
            top: 15%;
            left: 0%;
            transform: translate(0%, -15%);
            width: 100%;
        }
    }
}