@media only screen and (max-width:1201px) {}
@media only screen and (min-width:993px) and (max-width:1200px) {}
@media only screen and (min-width:768px) and (max-width:992px) {
    .knowledgec-entre-slider-section {
        .slick-next {
            background: url('../../../../public/images/small-right.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            right: -5px;
            &::before {
                display: none;
            }
        }
        .slick-slider {
            .slick-slide {
                margin: 30px 0px;
                padding: 20px 15px;
                transition: all 0.5s ease-in-out;
            }
        }
        .slick-prev {
            background: url('../../../../public/images/small-left.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            left: -5px;
            &::before {
                display: none;
            }
        }
    }
}
@media only screen and (min-width:321px) and (max-width:767px) {
    .knowledgec-entre-slider-section {
        .slick-next {
            background: url('../../../../public/images/small-right.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            right: -5px;
            &::before {
                display: none;
            }
        }
        .slick-slider {
            .slick-slide {
                margin: 30px 0px;
                padding: 20px 15px;
                transition: all 0.5s ease-in-out;
            }
        }
        .slick-prev {
            background: url('../../../../public/images/small-left.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            left: -5px;
            &::before {
                display: none;
            }
        }
    }
}
@media only screen and (min-width:100px) and (max-width:320px) {
    .knowledgec-entre-slider-section {
        .slick-next {
            background: url('../../../../public/images/small-right.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            right: -5px;
            &::before {
                display: none;
            }
        }
        .slick-slider {
            .slick-slide {
                margin: 30px 0px;
                padding: 20px 15px;
                transition: all 0.5s ease-in-out;
            }
        }
        .slick-prev {
            background: url('../../../../public/images/small-left.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            left: -5px;
            &::before {
                display: none;
            }
        }
    }
}