.over-global-energy-enquiry-section {
    background: #000;
    background-image: url("../../../../public/images/global-footprints/ab-pattern-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .enquiry-heading {
        padding: 30px 0px;

        h5 {
            font-weight: 700;
        }
    }

    .enquiry-left-d {
        text-align: left;

        img {
            width: 70%;
        }
    }

    .enquiry-form-right-dv {

        .enq-submit-btn {
            text-align: left;
            background-color: #000;
            color: #fff;
            font-weight: 700;
            border: 1px solid #000;
            padding: 5px 20px;
        }
    }
    .react-international-phone-input{
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-body-color);
        appearance: none;
        background-color: var(--bs-body-bg);
        background-clip: padding-box;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    
        border: none;
      
    }
}