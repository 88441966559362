.career-page-content-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.career-page-content-section .career-content-heading h5 {
  font-weight: 700;
}
.career-page-content-section .form-parent-custon-dv {
  background-color: #F1F0F0;
  padding: 40px;
  border-radius: 5px;
  margin: 20px 0px;
}
.career-page-content-section .form-parent-custon-dv .custom-row-form {
  text-align: left;
}
.career-page-content-section .form-parent-custon-dv .custom-row-form input::-moz-placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.career-page-content-section .form-parent-custon-dv .custom-row-form input::placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.career-page-content-section .form-parent-custon-dv .custom-row-form .submit-btn-custom {
  background-color: #000;
  color: #fff;
}
.career-page-content-section .form-parent-custon-dv .form-container .upload-files-container {
  width: 100%;
  padding: 0px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.career-page-content-section .form-parent-custon-dv .form-container .drag-file-area {
  border: 2px dashed #7b2cbf;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
}
.career-page-content-section .form-parent-custon-dv .form-container .drag-file-area .upload-icon {
  font-size: 50px;
}
.career-page-content-section .form-parent-custon-dv .form-container .drag-file-area h3 {
  font-size: 18px;
  margin: 15px 0;
}
.career-page-content-section .form-parent-custon-dv .form-container .drag-file-area label {
  font-size: 18px;
}
.career-page-content-section .form-parent-custon-dv .form-container .drag-file-area label .browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}
.career-page-content-section .form-parent-custon-dv .form-container .browse-files span {
  position: relative;
  top: 0;
}
.career-page-content-section .form-parent-custon-dv .form-container .default-file-input {
  opacity: 0;
}
.career-page-content-section .form-parent-custon-dv .form-container .cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #BB0000;
  display: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.career-page-content-section .form-parent-custon-dv .form-container .cannot-upload-message span,
.career-page-content-section .form-parent-custon-dv .form-container .upload-button-icon {
  padding-right: 10px;
}
.career-page-content-section .form-parent-custon-dv .form-container .cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}
.career-page-content-section .form-parent-custon-dv .form-container .file-block {
  color: #f7fff7;
  background-color: #7b2cbf;
  transition: all 1s;
  width: 100%;
  position: relative;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}
.career-page-content-section .form-parent-custon-dv .form-container .file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.career-page-content-section .form-parent-custon-dv .form-container .file-icon {
  margin-right: 10px;
}
.career-page-content-section .form-parent-custon-dv .form-container .file-name,
.career-page-content-section .form-parent-custon-dv .form-container .file-size {
  padding: 0 3px;
}
.career-page-content-section .form-parent-custon-dv .form-container .remove-file-icon {
  cursor: pointer;
}
.career-page-content-section .form-parent-custon-dv .form-container .progress-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 4.5%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4BB543;
}
.career-page-content-section .form-parent-custon-dv .form-container .upload-button {
  background-color: #7b2cbf;
  color: #f7fff7;
  display: flex;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}
.career-page-content-section .form-parent-custon-dv .form-container .file-label-cus {
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
}
.career-page-content-section .form-parent-custon-dv .form-container .file-label-cus span {
  color: #818181;
  font-size: 14px;
  font-style: italic;
}/*# sourceMappingURL=CareerPageContent.css.map */