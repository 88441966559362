.quality-assurance-banner-sec {
  background: #000;
  background-position: center;
  background-size: cover;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
}
.quality-assurance-banner-sec .business-heading-top h3 {
  font-weight: 700;
  font-size: 60px;
}
.quality-assurance-banner-sec .business-heading {
  position: relative;
  text-align: center;
}
.quality-assurance-banner-sec .business-heading img {
  height: 60px;
}
.quality-assurance-banner-sec .business-heading h3 {
  font-weight: 700;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}
.quality-assurance-banner-sec .banner-top-img-bg-rel {
  position: relative;
}
.quality-assurance-banner-sec .banner-top-img-bg-rel img.banner-img-tag {
  width: 100%;
}
.quality-assurance-banner-sec .banner-top-img-bg-rel .banner-top-content-absol {
  position: absolute;
  bottom: 15%;
  left: 0%;
  transform: translate(0%, -15%);
  width: 100%;
}

.quality-assurance-content-sec .new-page-heading h5 {
  width: 42%;
}/*# sourceMappingURL=QualityAssurance.css.map */