@media only screen and (min-width: 768px) and (max-width: 992px) {
  .knowledgec-entre-slider-section .slick-next {
    background: url("../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -5px;
  }
  .knowledgec-entre-slider-section .slick-next::before {
    display: none;
  }
  .knowledgec-entre-slider-section .slick-slider .slick-slide {
    margin: 30px 0px;
    padding: 20px 15px;
    transition: all 0.5s ease-in-out;
  }
  .knowledgec-entre-slider-section .slick-prev {
    background: url("../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -5px;
  }
  .knowledgec-entre-slider-section .slick-prev::before {
    display: none;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .knowledgec-entre-slider-section .slick-next {
    background: url("../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -5px;
  }
  .knowledgec-entre-slider-section .slick-next::before {
    display: none;
  }
  .knowledgec-entre-slider-section .slick-slider .slick-slide {
    margin: 30px 0px;
    padding: 20px 15px;
    transition: all 0.5s ease-in-out;
  }
  .knowledgec-entre-slider-section .slick-prev {
    background: url("../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -5px;
  }
  .knowledgec-entre-slider-section .slick-prev::before {
    display: none;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .knowledgec-entre-slider-section .slick-next {
    background: url("../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -5px;
  }
  .knowledgec-entre-slider-section .slick-next::before {
    display: none;
  }
  .knowledgec-entre-slider-section .slick-slider .slick-slide {
    margin: 30px 0px;
    padding: 20px 15px;
    transition: all 0.5s ease-in-out;
  }
  .knowledgec-entre-slider-section .slick-prev {
    background: url("../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -5px;
  }
  .knowledgec-entre-slider-section .slick-prev::before {
    display: none;
  }
}/*# sourceMappingURL=ResponsiveKnowledgeCentreSlider.css.map */