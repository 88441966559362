@media only screen and (min-width: 768px) and (max-width: 992px) {
  .our-specialization-section {
    background: rgba(204, 204, 204, 0.3607843137);
    background-image: url("../../../../public/images/our-speciali-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;
  }
  .our-specialization-section .specialization-heading-dv h5 {
    font-weight: 700;
  }
  .our-specialization-section .specialization-image img {
    width: 100%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .our-specialization-section {
    background: rgba(204, 204, 204, 0.3607843137);
    background-image: url("../../../../public/images/our-speciali-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;
  }
  .our-specialization-section .specialization-heading-dv h5 {
    font-weight: 700;
  }
  .our-specialization-section .specialization-image img {
    width: 100%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .our-specialization-section {
    background: rgba(204, 204, 204, 0.3607843137);
    background-image: url("../../../../public/images/our-speciali-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;
  }
  .our-specialization-section .specialization-heading-dv h5 {
    font-weight: 700;
  }
  .our-specialization-section .specialization-image img {
    width: 100%;
  }
}/*# sourceMappingURL=ResponsiveOurSpecialization.css.map */