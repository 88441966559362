.director-message-page-section {
  padding: 30px 0px;
}
.director-message-page-section .director-image {
  padding: 15px;
  border: 1px solid #FDBF05;
}
.director-message-page-section .director-image-name-post h5 {
  color: #AA000D;
  font-weight: 500;
}/*# sourceMappingURL=DirectorMessagePage.css.map */