@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:321px) and (max-width:767px) {

    .footer-section {
        .footer-inner-dv {
            .footer-content-parent {
                .footer-content {
                    .footer-content {
                        .follow-us-ul {
                            ul {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                align-content: center;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }




}

@media only screen and (min-width:100px) and (max-width:320px) {

    .footer-section {
        .footer-inner-dv {
            .footer-content-parent {
                .footer-content {
                    .footer-content {
                        .follow-us-ul {
                            ul {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                align-content: center;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}