@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .design-solar-power-load-calculator-section {
        .design-before-row-dv {
            // background-image: url("../../../../public/images/bg.jpg");
            padding: 30px 0px;
            background-position: right;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .content-parent-dv {
                .content-inner-first {
                    h4 {
                        color: #fff;
                        font-weight: 600;
                        font-size: 20px;
                    }

                    h5 {
                        color: #fff;
                        font-weight: 500;
                        font-size: 15px;
                    }
                }

                .content-inner-second {
                    .second-inner-button {
                        margin: 4px 0px;
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .design-solar-power-load-calculator-section {
        .design-before-row-dv {
            // background-image: url("../../../../public/images/bg.jpg");
            padding: 30px 0px;
            background-position: 0 0;
            background-size: 155% 100%;
            background-repeat: no-repeat;

            .content-parent-dv {
                .content-inner-first {
                    h4 {
                        color: #fff;
                        font-weight: 600;
                        font-size: 20px;
                    }

                    h5 {
                        color: #fff;
                        font-weight: 500;
                        font-size: 15px;
                    }
                }

                .content-inner-second {
                    .second-inner-button {
                        margin: 4px 0px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
