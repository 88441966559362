.e-catalogue-banner-page-banner-section {
    background: #000;
    // background-image: url("../../../../public/images/help-and-support/heading-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;

    // padding: 80px 0px;
    // height: 400px;
    .business-heading {
        position: relative;
        text-align: center;
        // margin-left: z-1%;

        img {
            height: 100px;
            width: 50%;
        }

        .business-banner-heading {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(0%, -50%);
            width: 100%;

            h1 {
                font-weight: 700;
                font-size: 40px;
            }

            h5 {
                font-weight: 700;
                width: 45%;
            }
        }

    }

    .banner-top-img-bg-rel-parent {
        .banner-top-img-bg-rel {
            position: relative;
            text-align: center;

            img.banner-img-tag {
                width: 100%;
            }

            .banner-top-content-absol {
                position: absolute;
                top: 80%;
                left: 0%;
                transform: translate(0%, -80%);
                width: 100%;
            }
        }
    }

}



@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .e-catalogue-banner-page-banner-section {
        .business-heading {
            position: relative;
            text-align: center;

            img {
                height: 100px;
                width: 50%;
            }

            .business-banner-heading {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;

                h1 {
                    font-weight: 700;
                    font-size: 28px;
                }

                h5 {
                    font-weight: 700;
                    font-size: 20px;
                    width: 100%;
                }
            }

        }

        .banner-top-img-bg-rel {
            position: relative;

            .banner-top-content-absol {
                position: absolute;
                top: 80%;
                left: 0%;
                transform: translate(0%, -80%);
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width:321px) and (max-width:767px) {
    .e-catalogue-banner-page-banner-section {
        .business-heading {
            position: relative;
            text-align: center;
            // margin-left: -1%;

            img {
                height: 100px;
                width: 50%;
            }

            .business-banner-heading {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;

                h1 {
                    font-weight: 700;
                    font-size: 20px;
                }

                h5 {
                    font-weight: 700;
                    font-size: 16px;
                    width: 100%;
                }
            }

        }

        .banner-top-img-bg-rel {
            position: relative;

            .banner-top-content-absol {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width:100px) and (max-width:320px) {
    .e-catalogue-banner-page-banner-section {
        .business-heading {
            position: relative;
            text-align: center;
            // margin-left: -1%;

            img {
                height: 100px;
                width: 50%;
            }

            .business-banner-heading {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;

                h1 {
                    font-weight: 700;
                    font-size: 16px;
                }

                h5 {
                    font-weight: 700;
                    font-size: 14px;
                    width: 100%;
                }
            }

        }

        .banner-top-img-bg-rel {
            position: relative;

            .banner-top-content-absol {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;
            }
        }
    }
}