.our-product-section {
  background: #cccccc5c;
  background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;

  .heading-dv {
    h3 {
      font-weight: 700;
      color: #000;
      position: relative;
      text-transform: uppercase;
      padding-bottom: 5px;
      font-size: 22px;
    }

    h3:before {
      width: 8px;
      height: 8px;
      display: block;
      content: "";
      position: absolute;
      bottom: 0px;
      left: 49.5%;
      top: 73%;
      margin-left: 0;
      margin-right: 0;
      transform: rotate(45deg);
      background-color: #000000;
      border: 2px solid #fff;
      z-index: 11;
    }

    h3:after {
      width: 146px;
      height: 1px;
      display: block;
      content: "";
      position: relative;
      margin-top: 8px;
      left: 50%;
      top: 50%;
      background-color: #000000;
      transform: translate(-50%, -50%);
    }
  }

  .product-card-row {
    margin: 30px 0px;

    .product-card-items {
      padding: 20px;
      // width: 500px !important;
      a {
        text-decoration: none;
        color: #000;
      }

      .product-card-right {
        border: 1px solid #f0bc16;
        background-color: #fff;
        border-radius: 5px 5px 0px 0px;

        .product-heading {
          background-color: #000;
          padding: 12px 0px;
          border-radius: 5px 5px 0px 0px;

          h6 {
            color: #fff;
            margin-bottom: 0px;
            font-size: 20px;
            font-weight: 700;
          }
        }

        .product-image {
          width: 100%;
          height: 210px;
          text-align: center;
          margin: 15px auto;
          justify-content: center;
          display: flex;
          img {
            // margin: auto 0;
            height: 210px;
          }
        }

        .product-details-content {
          p {
            padding: 10px;
            text-align: left;
          }
        }
      }
    }
  }
}
