.product-details-page-banner-section .slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec {
  position: relative;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec {
  position: fixed;
  right: 0;
  z-index: -1;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec img {
  height: 400px;
  width: 500px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one {
  height: 450px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card {
  margin: 9% 0;
  background-color: #f2f2f0;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec {
  padding: 0 20%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec img {
  height: 250px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec {
  position: relative;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .slick-next:before {
  color: #000;
  content: "\f054";
  font-family: FontAwesome;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .slick-prev:before {
  color: #000;
  content: "\f053";
  font-family: FontAwesome;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .slick-slide {
  scale: 0.8;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .slick-current {
  scale: 0.9;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card {
  border: 1px solid #c1c1c1;
  background-color: #fff;
  box-shadow: none;
  margin-right: 5px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body .img-sec {
  padding: 5%;
  height: 70px;
  width: 80px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body .img-sec img {
  height: 100%;
  width: 100%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two {
  height: 450px;
  position: relative;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .img-sec {
  position: fixed;
  right: 0;
  padding-left: 180px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec h1 {
  font-size: 32px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec p {
  font-weight: 400;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .scroll-height-fix-dv {
  height: 284px;
  overflow-y: scroll;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .scroll-height-fix-dv::-webkit-scrollbar-track {
  -webkit-box-shadow: unset;
  background-color: transparent;
  border: 1px solid transparent;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .scroll-height-fix-dv::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .scroll-height-fix-dv::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec {
  padding-right: 30%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec p {
  font-size: 14px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec p {
  font-weight: 400;
  font-size: 15px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec ul li {
  font-size: 14px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns {
  display: flex;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border: none;
  background-color: #fcc300;
  font-size: 14px;
  font-weight: 500;
  padding: 1% 2%;
  margin-right: 15px;
  width: -moz-max-content;
  width: max-content;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one img {
  height: 25px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one:hover {
  background-color: #d9d9d9;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border: none;
  background-color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  padding: 1% 2%;
  margin-right: 15px;
  width: -moz-max-content;
  width: max-content;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two img {
  height: 25px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two:hover {
  background-color: #fdbf05;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border: none;
  background-color: #fdbf05;
  font-size: 14px;
  font-weight: 500;
  padding: 1% 2%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec {
  position: relative;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec img {
  height: 25px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec .font-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  font-size: 80%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three:hover {
  background-color: #d9d9d9;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three {
  position: relative;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs {
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link.active {
  background: transparent;
  color: #000;
  font-weight: 700;
  border: none;
  border-bottom: 1px solid !important;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link {
  color: #000;
  border: none;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table {
  border: 1px solid #bbbbb7;
  margin-top: 30px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table .t-heading th {
  padding-top: 40px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table tr {
  border-bottom: 1px solid #bbbbb7;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane .matter-list .group-lists {
  margin-top: 40px;
  border: 1px solid rgba(165, 164, 164, 0.308);
  background-color: #fff;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane .matter-list .group-lists .lists-blank {
  border: 1px solid rgba(190, 190, 190, 0.322);
  padding: 5px 5px 10px 35px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane .matter-list .group-lists .lists-blank .blank {
  visibility: hidden;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane .matter-list .group-lists .lists {
  border: 1px solid rgba(190, 190, 190, 0.322);
  padding: 5px 5px 10px 35px;
  text-align: start;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec {
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec img {
  width: 500px;
  height: 400px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three {
  background-color: #f2f2f0;
  padding: 50px 0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading {
  justify-content: center;
  align-items: center;
  display: flex;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading h5 {
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one {
  background: #fff;
  height: 100%;
  padding: 40px 20px;
  padding-top: 0.5rem;
  border: 1px solid;
  text-align: center;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec {
  text-align: center;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec i {
  font-size: 20px;
  margin-right: 10px;
  color: #e3e3e2;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec .rated {
  color: #fdbf05;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec .unrated {
  color: #8c8c89;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one h1 {
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one h6 {
  font-weight: 700;
  margin-bottom: 30px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two {
  height: 100%;
  background: #fff;
  border: 1px solid;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two h6 {
  margin-bottom: 20px;
  text-align: left;
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five {
  display: flex;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .number-five,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .number-five,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .number-five,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .number-five,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .number-five,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
  font-size: 17px;
  width: 30px;
  color: #8c8c89;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .star-icon,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .star-icon,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .star-icon,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .star-icon,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .star-icon {
  font-size: 16px;
  width: 50px;
  color: #fdbf05;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress {
  height: 15px;
  width: 85%;
  margin-top: 0.5%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress .progress-bar,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress .progress-bar,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress .progress-bar,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress .progress-bar,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress .progress-bar {
  height: 20px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
  padding-left: 20px;
  font-size: 16px;
  width: 50px;
  color: #8c8c89;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .write-review-btn {
  margin-top: 40px;
  color: #000;
  background-color: #fcc300;
  border: none;
  padding: 10px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four {
  background-color: #f2f2f0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec {
  text-align: center;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec h5 {
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec p {
  font-weight: 600;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one {
  text-align: center;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .star-icon i {
  font-size: 30px;
  margin-right: 10px;
  color: #e3e3e2;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .star-icon i.rated {
  color: #fdbf05;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .input-sec input,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .textarea-sec input {
  width: 80%;
  border-radius: 5px;
  padding: 5px 0;
  border: 1px solid #c0c0c0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .input-sec textarea,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .textarea-sec textarea {
  width: 80%;
  height: 130px;
  border-radius: 5px;
  border: 1px solid #c0c0c0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .submit-review-btn {
  margin: 0 0 40px 0;
  color: #000;
  background-color: #fcc300;
  border: none;
  padding: 10px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two,
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
  border: none;
  height: 100%;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one {
  border-radius: 10px 0 0 10px;
  background-color: #d9d9d9;
  padding: 50px 20px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two {
  background-color: white;
  padding: 50px 0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two h5 {
  color: #fdbf05;
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-one {
  color: #8c8c89;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-two {
  color: #8c8c89;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  padding: 20px 0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .star-icon {
  margin-bottom: 10px;
  text-align: start;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .star-icon i {
  font-size: 20px;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec {
  text-align: start;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec h6 {
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para {
  text-align: start;
  padding: 0 10px 0 0;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para p {
  font-size: 100%;
  color: #8c8c89;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn {
  justify-content: center;
  align-items: center;
  display: flex;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn {
  background: #fdbf05;
  font-weight: 700;
}
.product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn a {
  color: #000;
  text-decoration: none;
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .product-details-page-banner-section .screen-sixteen-nine-four-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec {
    position: fixed;
    right: 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec img {
    height: 400px;
    width: 500px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one {
    height: 450px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card {
    margin: 9% 0;
    background-color: #f2f2f0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec {
    padding: 0 20%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec img {
    height: 250px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card {
    border: 1px solid #c1c1c1;
    background-color: #fff;
    box-shadow: none;
    margin-right: 5px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body .img-sec {
    padding: 4%;
    height: 60px;
    width: 60px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body .img-sec img {
    height: 100%;
    width: 100%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two {
    height: 450px;
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .img-sec {
    position: absolute;
    right: 0;
    padding-left: 180px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec h1 {
    font-size: 32px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec p {
    font-weight: 400;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec {
    padding-right: 30%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec p {
    font-size: 14px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec p {
    font-weight: 400;
    font-size: 15px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec ul li {
    font-size: 14px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns {
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    background-color: #fcc300;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 1%;
    margin-right: 8px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one:hover {
    background-color: #d9d9d9;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    background-color: #d9d9d9;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 1%;
    margin-right: 8px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two:hover {
    background-color: #fdbf05;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    background-color: #fdbf05;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 1%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec .font-icon {
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 80%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three:hover {
    background-color: #d9d9d9;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs {
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link.active {
    color: #000;
    font-weight: 700;
    border: none;
    border-bottom: 1px solid !important;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link {
    color: #000;
    border: none;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table {
    border: 1px solid #bbbbb7;
    margin-top: 30px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table .t-heading th {
    padding-top: 30px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table tr {
    border-bottom: 1px solid #bbbbb7;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec img {
    width: 500px;
    height: 400px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one {
    padding: 40px 20px;
    border: 1px solid;
    text-align: center;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two {
    border: 1px solid;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five {
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
    font-size: 17px;
    width: 30px;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .star-icon {
    font-size: 16px;
    width: 50px;
    color: #fdbf05;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress {
    height: 16px;
    width: 600px;
    margin-top: 0.5%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress .progress-bar {
    height: 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
    padding-left: 20px;
    font-size: 16px;
    width: 50px;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec {
    text-align: center;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec p {
    font-weight: 600;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one {
    background-color: #f2f2f0;
    text-align: center;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .input-sec input,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .textarea-sec input {
    width: 80%;
    border-radius: 5px;
    padding: 5px 0;
    border: 1px solid #c0c0c0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .input-sec textarea,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .textarea-sec textarea {
    width: 80%;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    border: none;
    height: 100%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one {
    border-radius: 10px 0 0 10px;
    background-color: #d9d9d9;
    padding: 50px 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two {
    background-color: white;
    padding: 50px 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two h5 {
    color: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-one {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-two {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    padding: 20px 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .star-icon {
    margin-bottom: 10px;
    text-align: start;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .star-icon i {
    font-size: 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec {
    text-align: start;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec h6 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para {
    text-align: start;
    padding: 0 10px 0 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para p {
    font-size: 90%;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn {
    background: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn a {
    color: #000;
    text-decoration: none;
  }
}
@media only screen and (min-width: 568px) and (max-width: 992px) {
  .product-details-page-banner-section .screen-sixteen-nine-four-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec {
    position: fixed;
    right: 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec img {
    height: 400px;
    width: 500px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one {
    height: 400px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card {
    margin: 9% 0;
    background-color: #f2f2f0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec {
    padding: 0 20%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec img {
    height: 250px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card {
    border: 1px solid #c1c1c1;
    background-color: #fff;
    box-shadow: none;
    margin-right: 5px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body .img-sec img {
    height: 80px;
    width: 80px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .img-sec {
    position: absolute;
    right: 0;
    padding-left: 180px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec h1 {
    font-size: 32px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec p {
    font-weight: 400;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec {
    padding-right: 30%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec p {
    font-size: 14px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec p {
    font-weight: 400;
    font-size: 15px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec ul li {
    font-size: 14px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    background-color: #fcc300;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 5%;
    margin-right: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    background-color: #d9d9d9;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 5%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs {
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link.active {
    color: #000;
    font-weight: 700;
    border: none;
    border-bottom: 1px solid !important;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link {
    color: #000;
    border: none;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table {
    border: 1px solid #bbbbb7;
    margin-top: 30px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table .t-heading th {
    padding-top: 30px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table tr {
    border-bottom: 1px solid #bbbbb7;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec img {
    width: 500px;
    height: 400px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one {
    padding: 40px 20px;
    border: 1px solid;
    text-align: center;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec i {
    margin-right: 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two {
    border: 1px solid;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five {
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
    font-size: 17px;
    width: 30px;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .star-icon {
    font-size: 16px;
    width: 50px;
    color: #fdbf05;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress {
    height: 15px;
    width: 600px;
    margin-top: 1%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress .progress-bar {
    height: 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
    padding-left: 20px;
    font-size: 16px;
    width: 50px;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec {
    text-align: center;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec p {
    font-weight: 600;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one {
    background-color: #f2f2f0;
    text-align: center;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .star-icon i {
    font-size: 30px;
    color: #fdbf05;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .input-sec input,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .textarea-sec input {
    width: 80%;
    border-radius: 5px;
    padding: 5px 0;
    border: 1px solid #c0c0c0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .input-sec textarea,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .form-sec .textarea-sec textarea {
    width: 80%;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    border: none;
    height: 100%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one {
    border-radius: 10px 0 0 10px;
    background-color: #d9d9d9;
    padding: 50px 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two {
    background-color: white;
    padding: 30% 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two h5 {
    color: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-one {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-two {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    padding: 10px 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .star-icon {
    margin-bottom: 10px;
    text-align: start;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .star-icon i {
    font-size: 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec {
    text-align: start;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec h6 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para {
    text-align: start;
    padding-bottom: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para p {
    font-size: 70%;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn {
    background: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn a {
    color: #000;
    text-decoration: none;
  }
}
@media only screen and (min-width: 568px) and (max-width: 767px) {
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec i {
    margin-right: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec {
    text-align: center;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec p {
    font-weight: 600;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    border: none;
    height: 100%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one {
    border-radius: 10px 10px 0 0 !important;
    background-color: #d9d9d9;
    padding: 50px 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two {
    background-color: white;
    padding: 20px 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two h5 {
    color: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-one {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-two {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec {
    text-align: start;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec h6 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para {
    text-align: start;
    padding-bottom: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para p {
    font-size: 70%;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn {
    background: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn a {
    color: #000;
    text-decoration: none;
  }
}
@media only screen and (min-width: 200px) and (max-width: 567px) {
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec {
    position: fixed;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-img-sec img {
    height: 300px;
    width: 300px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one {
    height: 320px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card {
    margin: 9% 0;
    background-color: #f2f2f0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec {
    padding: 0 10%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .img-sec img {
    height: 200px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card {
    border: 1px solid #c1c1c1;
    background-color: #fff;
    box-shadow: none;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-one .card .card-body .slider-sec .card .card-body .img-sec img {
    height: 100%;
    width: 100%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two {
    height: 620px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .img-sec {
    padding-left: 180px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec h1 {
    font-size: 32px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .heading-sec p {
    font-weight: 400;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec {
    padding-right: 30%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .para-sec p {
    font-size: 14px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec p {
    font-weight: 400;
    font-size: 15px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .keyfeatures-sec ul li {
    font-size: 14px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns {
    display: block;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one {
    width: 100%;
    font-size: 14px;
    padding: 1% 5%;
    margin: 5px 0px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-one img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two {
    width: 100%;
    font-size: 14px;
    padding: 1% 5%;
    margin: 5px 0px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-two img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: none;
    background-color: #fdbf05;
    width: 100%;
    font-size: 14px;
    margin: 5px 0px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec img {
    height: 25px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-one .section-one-parent-two .section-one-parent-two-child .section-one-parent-two-sub-child .btns .btn-three .btn-three-img-sec .font-icon {
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 80%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three {
    position: relative;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs {
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link.active {
    color: #000;
    font-weight: 700;
    border: none;
    border-bottom: 1px solid !important;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .nav.nav-tabs .nav-item .nav-link {
    color: #000;
    border: none;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table {
    border: 1px solid #bbbbb7;
    margin-top: 30px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table .t-heading th {
    padding-top: 30px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .tab-content .tab-pane table tr {
    border-bottom: 1px solid #bbbbb7;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-two .section-one-parent-three .screen-sixteen-nine-four-section-two-img-sec img {
    width: 300px;
    height: 300px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .heading h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one {
    padding: 40px 20px;
    border: 1px solid;
    text-align: center;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-one .icon-sec i {
    margin-right: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two {
    border: 1px solid;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five {
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .number-five,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
    font-size: 13px;
    width: 30px;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .star-icon,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .star-icon {
    font-size: 13px;
    width: 50px;
    color: #fdbf05;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress {
    height: 14px;
    width: 600px;
    margin-top: 0.5%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .progress .progress-bar,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .progress .progress-bar {
    height: 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-one .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-two .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-three .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-four .percent,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-three .section-one-parent-four .section-one-parent-four-content-box-two .progress-five .percent {
    padding-left: 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec {
    text-align: center;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec h5 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .heading-sec p {
    font-weight: 600;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two,
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    border: none;
    height: 100%;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-one {
    border-radius: 10px 10px 0 0 !important;
    background-color: #d9d9d9;
    padding: 50px 20px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two {
    background-color: white;
    padding: 20px 0;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two h5 {
    color: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-one {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-two .para-two {
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three {
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec {
    text-align: start;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .heading-sec h6 {
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para {
    text-align: start;
    padding-bottom: 10px;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .section-two-sub-child .section-two-sub-child-parent-one .section-two-sub-child-three .para p {
    font-size: 70%;
    color: #8c8c89;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn {
    background: #fdbf05;
    font-weight: 700;
  }
  .product-details-page-banner-section .screen-sixteen-nine-four-sec .screen-sixteen-nine-four-section-four .section-two-parent-one .section-two-child-sec .section-two-child-one .show-more-btn .btn a {
    color: #000;
    text-decoration: none;
  }
}/*# sourceMappingURL=ProductDetailsPage.css.map */