.export-enquiry-section{
    background: #000;
    background-image: url("../../../../public/images/global-footprints/ab-pattern-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;
    .enquiry-left-d{
        text-align: left;
        img{
            width: 80%;
        }
    }
    .enquiry-form-right-dv{
        .enquiry-heading{
            text-align: left;
            padding: 30px 0px;
            h5{
                font-weight: 700;
            }
        }
        .enq-submit-btn{
            text-align: left;
            background-color: #000;
            color: #fff;
            font-weight: 700;
            border: 1px solid #000;
            padding: 5px 20px;
        }
    }
}