.about-us-content-dark-section {
  background: #000;
}
.about-us-content-dark-section .left-images-dv {
  height: 100%;
}
.about-us-content-dark-section .left-images-dv img {
  height: 100%;
}
.about-us-content-dark-section .right-about-content-dv {
  padding: 50px;
  color: #fff;
}
.about-us-content-dark-section .right-about-content-dv .about-heading-dv h5 {
  font-weight: 600;
  font-size: 22px;
}
.about-us-content-dark-section .right-about-content-dv .about-content p {
  text-align: justify;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-dark-section .right-about-content-dv {
    padding: 50px;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .about-us-content-dark-section .right-about-content-dv::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  .about-us-content-dark-section .right-about-content-dv::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  .about-us-content-dark-section .right-about-content-dv::-webkit-scrollbar-track {
    border: none;
    background-color: transparent;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .about-us-content-dark-section .right-about-content-dv {
    padding: 10px;
  }
}/*# sourceMappingURL=AboutUsContentDark.css.map */