@media only screen and (min-width: 768px) and (max-width: 992px) {
  .what-eapro-team-mate-slider-section .slick-next {
    background: url("../../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -5px;
  }
  .what-eapro-team-mate-slider-section .slick-next::before {
    display: none;
  }
  .what-eapro-team-mate-slider-section .slick-slider {
    padding: 50px 0px;
  }
  .what-eapro-team-mate-slider-section .slick-slider .slick-slide {
    margin: 85px 0px 0px 0px;
    padding: 20px 15px;
    transition: all 0.5s ease-in-out;
  }
  .what-eapro-team-mate-slider-section .slick-prev {
    background: url("../../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -5px;
  }
  .what-eapro-team-mate-slider-section .slick-prev::before {
    display: none;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .what-eapro-team-mate-slider-section .slick-next {
    background: url("../../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -5px;
  }
  .what-eapro-team-mate-slider-section .slick-next::before {
    display: none;
  }
  .what-eapro-team-mate-slider-section .slick-slider {
    padding: 50px 0px;
  }
  .what-eapro-team-mate-slider-section .slick-slider .slick-slide {
    margin: 85px 0px 0px 0px;
    padding: 20px 15px;
    transition: all 0.5s ease-in-out;
  }
  .what-eapro-team-mate-slider-section .slick-prev {
    background: url("../../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -5px;
  }
  .what-eapro-team-mate-slider-section .slick-prev::before {
    display: none;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .what-eapro-team-mate-slider-section .slick-next {
    background: url("../../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -5px;
  }
  .what-eapro-team-mate-slider-section .slick-next::before {
    display: none;
  }
  .what-eapro-team-mate-slider-section .slick-slider {
    padding: 50px 0px;
  }
  .what-eapro-team-mate-slider-section .slick-slider .slick-slide {
    margin: 85px 0px 0px 0px;
    padding: 20px 15px;
    transition: all 0.5s ease-in-out;
  }
  .what-eapro-team-mate-slider-section .slick-prev {
    background: url("../../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -5px;
  }
  .what-eapro-team-mate-slider-section .slick-prev::before {
    display: none;
  }
}/*# sourceMappingURL=ResponsiveWhatEaproTeamMateSaySlider.css.map */