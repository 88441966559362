.meet-our-team-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.meet-our-team-section .our-team-parent-dv {
  padding: 50px 60px;
  height: 380px;
}
.meet-our-team-section .our-team-img {
  width: 330px;
  height: -moz-max-content;
  height: max-content;
  border-radius: 50%;
  -o-object-fit: fill;
     object-fit: fill;
}
.meet-our-team-section .teamimg {
  max-width: 210px;
  height: 175px;
  border-radius: 50%;
}
.meet-our-team-section .our-team-dv {
  background: #fff;
  border: 1px solid grey;
  border-radius: 5px;
}
.meet-our-team-section .our-team-heading h4 {
  font-size: 16px;
  font-weight: 700;
}
.meet-our-team-section .our-team-heading p {
  margin-bottom: 0px;
}
.meet-our-team-section .heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.meet-our-team-section .slick-slide img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.meet-our-team-section .slick-next {
  background: url("../../../../public/images/right.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
}
.meet-our-team-section .slick-next::before {
  display: none;
}
.meet-our-team-section .slick-prev {
  background: url("../../../../public/images/left.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
}
.meet-our-team-section .slick-prev::before {
  display: none;
}
.meet-our-team-section .slick-slider {
  width: 100%;
  background-color: transparent;
}
.meet-our-team-section .slick-slider .slick-slide {
  margin: 30px 0px;
  padding: 20px 50px;
  transition: all 0.5s ease-in-out;
}
.meet-our-team-section .slick-slider .slick-slide.slick-current .slider-item-dv, .meet-our-team-section .slick-slider .slick-slide.slick-center .slider-item-dv {
  background: #fff;
  border-radius: 5px;
}
.meet-our-team-section .slick-slider .slick-slide.slick-current .slider-item-dv .slider-item-img, .meet-our-team-section .slick-slider .slick-slide.slick-center .slider-item-dv .slider-item-img {
  text-align: center;
}
.meet-our-team-section .slick-slider .slick-slide.slick-current,
.meet-our-team-section .slick-slider .slick-slide.slick-center {
  transform: scale(1.05);
  padding: 23px 15px;
}
.meet-our-team-section .slider-parent-dv {
  padding: 50px 60px;
  height: 380px;
}
.meet-our-team-section .slider-parent-dv .slider-item-dv {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  padding: 23px;
}
.meet-our-team-section .slider-parent-dv .slider-item-dv .slider-item-img {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
}
.meet-our-team-section .slider-parent-dv .slider-item-dv .slider-item-heading h4 {
  font-size: 16px;
  font-weight: 700;
}
.meet-our-team-section .slider-parent-dv .slider-item-dv .slider-item-heading p {
  margin-bottom: 0px;
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-images-left-dv {
  background: #fff;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-images-left-dv img {
  width: 237px;
  height: 149px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-images-right-dv {
  background: #fff;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-images-right-dv img {
  width: 237px;
  height: 149px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-name-post {
  margin: 10px 0px;
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-name-post a {
  text-decoration: none;
  color: #000;
}
.meet-our-team-section .founder-md-section-parent-new-design-our-team .founder-name-post p {
  font-size: 14px;
}/*# sourceMappingURL=MeetOurTeamAbout.css.map */