@media only screen and (min-width: 321px) and (max-width: 767px) {
  .blog-page-content-section .blog-nav-tabs {
    border-bottom: 1px solid transparent;
    justify-content: center;
    padding: 50px 0px;
    flex-wrap: nowrap;
  }
  .blog-page-content-section .blog-nav-tabs .nav-item .nav-link {
    font-size: 14px;
  }
  .blog-page-content-section .blog-nav-tabs .nav-item .nav-link.active {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #000;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .blog-page-content-section .blog-nav-tabs {
    border-bottom: 1px solid transparent;
    justify-content: center;
    padding: 50px 0px;
    flex-wrap: wrap;
  }
}/*# sourceMappingURL=ResponsiveBlogContent.css.map */