@media only screen and (max-width: 1201px) {
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .store-locator-banner-section {
    background: #000;
    // background-image: url("../../../../public/images/store-locator/banner-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #f1f0f0;
    background-repeat: no-repeat;
    padding: 50px 0px;

    // filter: grayscale(1);
    .store-heading {
      position: relative;
      text-align: center;

      img {
        height: 60px;
      }

      h5 {
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .banner-img-dv {
      width: 100%;
      text-align: center;
      padding: 30px 0px;

      img {
        width: 450px;
      }
    }

    .banner-dv-search {
      .banner-search-parent {
        input {
          border-radius: 5px;
          border: 1px solid #ccc;
          box-shadow: none;
          padding: 5px 20px;
          margin-right: 20px;
          width: 270px;
        }
        input[placeholder] {
          font-size: 14px;
        }
        .banner-search-btn {
          box-shadow: none;
          background-color: #000;
          color: #ffc300;
          border: 1px solid #ccc;
          padding: 5px 20px;
          border-radius: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .store-locator-banner-section {
    background: #000;
    // background-image: url("../../../../public/images/store-locator/banner-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #f1f0f0;
    background-repeat: no-repeat;
    padding: 50px 0px;

    // filter: grayscale(1);
    .store-heading {
      position: relative;
      text-align: center;

      img {
        height: 60px;
        width: 100%;
      }

      h5 {
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .banner-img-dv {
      width: 100%;
      text-align: center;
      padding: 30px 0px;

      img {
        width: 450px;
      }
    }

    .banner-dv-search {
      .banner-search-parent {
        input {
          border-radius: 5px;
          border: 1px solid #ccc;
          box-shadow: none;
          padding: 5px 20px;
          margin-right: 20px;
          width: 270px;
        }
        input[placeholder] {
          font-size: 14px;
        }
        .banner-search-btn {
          box-shadow: none;
          background-color: #000;
          color: #ffc300;
          border: 1px solid #ccc;
          padding: 5px 20px;
          border-radius: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 405px) {
  .store-locator-banner-section {
    .banner-dv-search {
      .banner-search-parent {
        input {
          margin: 0 auto;
          margin-bottom: 10px;
        }
      }
    }
  }
}
