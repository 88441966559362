.pillar-videos-details-con-page-content-section {
  background: #000;
  background-image: url("../../../../../../public//images//help-and-support//color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.pillar-videos-details-con-page-content-section .blog-details-inner {
  text-align: left;
}
.pillar-videos-details-con-page-content-section .blog-details-inner-video {
  margin: 20px 0px;
  text-align: left;
}
.pillar-videos-details-con-page-content-section .container {
  text-align: left;
}/*# sourceMappingURL=PillarVideosDetailsContent.css.map */