.advanced-technology-content-sec {
  padding: 50px 0px;
}
.advanced-technology-content-sec .new-page-heading h1 {
  font-size: 42px;
  font-weight: 600;
  padding: 20px 0px;
}
.advanced-technology-content-sec .new-page-left {
  padding-top: 20px;
}
.advanced-technology-content-sec .new-page-left h5 {
  font-size: 20px;
  font-weight: 600;
}/*# sourceMappingURL=AdvancedTechnology.css.map */