.life-at-eapro-slider-section {
    background: #cccccc5c;
    background-image: url("../../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 50px 0px;

    .heading-dv {
        h5 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .heading-tabs-parent {
        .nav-tabs {
            border: none;
            justify-content: center;

            .nav-item {
                .nav-link {
                    font-weight: 700;
                    font-size: 16px;
                    color: #000;

                    &:hover {
                        border: none;
                        box-shadow: none;
                    }

                }

                .nav-link.blogs-btn {
                    position: relative;

                    &::before {
                        position: absolute;
                        height: 27px;
                        content: "";
                        width: 2px;
                        border: 1px solid #ccc;
                        background-color: red;
                        left: 3px;
                    }

                }

                .nav-link.active {
                    border: none;
                    border-bottom: 2px solid #ccc;
                    background-color: unset;

                }
            }
        }

        .slider-parent-dv {
            .slider-item-dv {

                .slider-item-img {
                    margin-bottom: 10px;
                    position: relative;
                    text-align: center;
                    img{
                        margin: 0 auto;
                        width: 600px;
                        height: 390px;
                    }
                    .play-btn-youtube {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .slider-item-heading {
                    background-color: #000;
                    color: #fff;
                    padding: 10px 5px;
                    text-align: left;

                    h4 {
                        font-size: 22px;
                        font-weight: 500;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 13px;

                        a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
            }
            .slick-next {
                background: url('../../../../../public/images/life-nav-right.png')center center no-repeat !important;
                width: 40px;
                height: 65px;
        
                &::before {
                    display: none;
                }
            }
        
            .slick-prev {
                background: url('../../../../../public/images/life-nav-left.png')center center no-repeat !important;
                width: 40px;
                height: 65px;
        
                &::before {
                    display: none;
                }
            }
        
            .slick-slider {
                width: 100%;
                background-color: transparent;
                // padding: 50px 60px;
                .slick-slide {
                    margin: 30px 0px 0px 0px;
                    // padding: 20px 10px;
                    transition: all 0.5s ease-in-out;
        
                    &.slick-current,
                    &.slick-center {
                        .slider-item-dv {}
        
                    }
                }
            }
        }
        .slider-parent-dv1 {
            .slider-item-dv {
                padding: 10px;
                .slider-item-img {
                    margin-bottom: 10px;
                    position: relative;
                    height: 150px;
                    width: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                    }
                    .play-btn-youtube {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .slider-item-heading {
                    background-color: #000;
                    color: #fff;
                    padding: 10px 5px;
                    text-align: left;

                    h4 {
                        font-size: 22px;
                        font-weight: 500;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 13px;

                        a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
            }
            .slick-slider {
                width: 100%;
                background-color: transparent;
                padding: 50px 60px;
                .slick-slide {
                    margin: 30px 20px;
                    padding: 20px 10px;
                    transition: all 0.5s ease-in-out;
        
                    &.slick-current,
                    &.slick-center {
                        .slider-item-dv {}
        
                    }
                }
            }
        }
    }

    .footer-view-all-btn {
        a {
            text-decoration: none;
            color: #000;
            font-size: 16px;
        }
    }


}