.our-journey-component-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/about-us/color-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px 0px 0px;
}
.our-journey-component-section .our-journey-heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.our-journey-component-section .journey-images-parent {
  margin: 20px 0px 0px 0px;
}/*# sourceMappingURL=OurJourneyComponent.css.map */