.power-solution-converter-and-controller-section {
    background: #cccccc5c;
    background-image: url("../../../../public/images/our-speciali-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .specialization-heading-dv {
        margin: 30px 0px;

        h5 {
            font-weight: 700;
            font-size: 22px;
        }
    }

    .specialization-image {}

    .left-images {}

    .right-content-parent {
        text-align: left;

        .img-content-dv {
            display: flex;
            align-items: center;

            img {}

            p {
                font-size: 14px;
                margin-left: 10px;
                margin-bottom: 0px;
            }
        }

        .view-product-learn-more {
            margin-top: 40px;

            .view-product-btn {
                color: #FFBC00;
                background-color: #000;
                text-decoration: none;
                padding: 7px 20px;
                margin: 0px 20px;
                border-radius: 5px;
            }
            .learn-more-btn{
                padding: 10px 20px; 
            }
        }
    }
}