@media only screen and (min-width: 200px) and (max-width: 767px) {
  .why-eapro-section {
    background: rgba(204, 204, 204, 0.3607843137);
    background-image: url("../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .why-eapro-section .why-heading-dv h5 {
    font-weight: 700;
  }
  .why-eapro-section .para-content p {
    text-align: justify;
  }
  .why-eapro-section .counter-row .card-box-counter {
    background-color: #000;
    padding: 20px 20px;
    color: #fff;
    margin: 5px 0px;
  }
}/*# sourceMappingURL=ResponsiveWhyEapro.css.map */