.e-catalogue-page-content-section {
  background: #000;
  background-image: url("../../../../public/images/e-catalogue/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.e-catalogue-page-content-section .e-catelogue-before-row-parent-dv .view-more-btn-cat {
  text-align: right;
  margin: 10px 0px;
}
.e-catalogue-page-content-section .e-catelogue-before-row-parent-dv .view-more-btn-cat a {
  color: #000;
  text-decoration: none;
}
.e-catalogue-page-content-section .e-catelogue-before-row-parent-dv .e-catalogue-heading {
  margin-bottom: 16px;
}
.e-catalogue-page-content-section .e-catelogue-before-row-parent-dv .e-catalogue-heading h5 {
  font-weight: 700;
}
.e-catalogue-page-content-section .e-catelogue-before-row-parent-dv .catalogue-card-parent-dv {
  border: 1px solid #ccc;
}
.e-catalogue-page-content-section .e-catelogue-before-row-parent-dv .catalogue-card-parent-dv .c-card-img {
  background-color: #fff;
  padding: 15px;
}
.e-catalogue-page-content-section .heading-down-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  background-color: #fdbf05;
  padding: 0px 10px;
  margin: 5px 0px 0px 0px;
}
.e-catalogue-page-content-section .heading-down-icon .first-content-strip p {
  font-weight: 500;
  margin-bottom: 0px;
  padding: 7px 0px;
}
.e-catalogue-page-content-section .heading-down-icon .first-content-strip p a {
  text-decoration: none;
  color: #000;
}
.e-catalogue-page-content-section .heading-down-icon .second-content-strip p {
  font-weight: 700;
  margin-bottom: 0px;
  padding: 7px 0px;
}
.e-catalogue-page-content-section .heading-down-icon .second-content-strip p a {
  text-decoration: none;
  color: #fff;
}/*# sourceMappingURL=ECataloguePageContent.css.map */