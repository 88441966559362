.group-of-eapro-companies-section {
    background: #cccccc5c;
    background-image: url("../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .heading-dv {
        h5 {
            font-weight: 700;
            font-size: 22px;
        }
    }

    .group-card-parent {
        border-radius: 10px;
        margin: 80px 0px 40px 0px;
        .group-card-icon-strip {
            position: relative;
            padding: 25px;
            height: 220px;

            .group-icon-dv {
                position: absolute;
                left: 0px;
                top: -70px;
                img{
                    width: 135px;
                    height: 100%;                
                }
            }

            .group-text-heading {
                background-color: #fff;
                padding: 25px 10px;
                border-radius: 10px;

                h6 {
                    margin-bottom: 0;
                    a {
                        text-decoration: none;
                        font-family: "Orbitron", sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        color: #000;
                        margin-left: 50px;
                        font-size: 13px;
                    }
                }
            }
            .group-content-para{
                p{
                    margin: 10px 0px;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 14px;
                }
            }
        }
    }
    .group-card-parent-yellow{
        background: rgb(255, 230, 1);
        background: radial-gradient(circle, rgba(255, 230, 1, 1) 0%, rgba(249, 178, 21, 1) 61%);
    }
    .group-card-parent-dark{
        background: rgb(0, 0, 0);
        background: radial-gradient(circle, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 61%);
    }

}