.banner-section {
  margin-bottom: 60px;
}
.banner-section .carousel .carousel-control-prev {
  width: 5% !important;
}
.banner-section .carousel .carousel-control-next {
  width: 5% !important;
}
.banner-section .carousel .carousel-indicators {
  margin-bottom: -35px;
}
.banner-section .carousel .carousel-indicators input {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.banner-section .carousel .carousel-indicators input.active {
  background-color: #ffc300 !important;
  opacity: 1;
}
.banner-section .carousel .carousel-inner {
  text-align: center;
  margin: auto;
}
.banner-section .carousel .carousel-inner .carousel-item {
  height: auto;
}
.banner-section .carousel .carousel-inner .carousel-item video {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner-section .carousel .carousel-inner .carousel-item img {
  width: 100%;
  height: auto;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption {
  position: absolute;
  top: 50%;
  bottom: 0px;
  transform: translate(0%, -50%);
  left: 5%;
  width: 50%;
  text-align: left;
  height: 70%;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .carousel-width {
  width: 100%;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .banner-heading {
  font-size: 40px;
  font-family: "Orbitron", sans-serif;
  line-height: 45px;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .para-name {
  font-size: 15px;
  font-style: italic;
  width: 100%;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .para-name span {
  color: #ffc300;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn {
  position: absolute;
  bottom: 0%;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn a {
  background-color: #ffc300;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #000;
  padding: 8px 15px;
  border-radius: 10px;
}
.banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn a i {
  margin-right: 6px;
  font-size: 18px;
}/*# sourceMappingURL=Banner.css.map */