@media only screen and (min-width: 321px) and (max-width: 767px) {
  .footer-section .footer-inner-dv .footer-content-parent .footer-content .footer-content .follow-us-ul ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .footer-section .footer-inner-dv .footer-content-parent .footer-content .footer-content .follow-us-ul ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=ResponsiveFooter.css.map */