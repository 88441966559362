.leading-reasearch-banner-sec{

}
.leading-reasearch-content-sec{
    padding: 50px 0px;
    .new-page-heading{
        h1{
            font-size: 42px;
            font-weight: 600;
            padding: 20px 0px;
        }
    }
    .new-page-content{
       
    }
    .new-page-left{
        padding-top: 20px;
        h5{
            font-size: 20px;
            font-weight: 600;
        }
    }
    .new-page-right{

    }
}