@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:200px) and (max-width:767px) {
    .why-eapro-section {
        background: #cccccc5c;
        background-image: url("../../../../public/images/why-bg-banner.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // padding: 50px 0px;

        .why-heading-dv {
            h5 {
                font-weight: 700;
                
            }
        }

        .para-content {
            p {
                text-align: justify;
            }
        }

        .counter-row {
            .card-box-counter {
                background-color: #000;
                padding: 20px 20px;
                color: #fff;
                margin: 5px 0px;
            }
        }
    }

}

@media only screen and (min-width:100px) and (max-width:320px) {}