.career-at-eapro-banner-section {
  background: #000;
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
}
.career-at-eapro-banner-section .banner-top-img-bg-rel {
  position: relative;
}
.career-at-eapro-banner-section .banner-top-img-bg-rel img {
  width: 100%;
}
.career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  width: 100%;
}
.career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol h3 {
  color: #FFC300;
  font-size: 37px;
  font-weight: 700;
}
.career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol p {
  color: #fff;
  font-size: 20px;
  width: 56%;
  line-height: 23px;
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol h3 {
    color: #FFC300;
    font-size: 18px;
    font-weight: 500;
  }
  .career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol p {
    color: #fff;
    font-size: 14px;
    width: 100%;
    line-height: 20px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 320px) {
  .career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol h3 {
    color: #FFC300;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .career-at-eapro-banner-section .banner-top-img-bg-rel .banner-top-content-absol p {
    color: #fff;
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }
}/*# sourceMappingURL=CareerAtEaproOneBanner.css.map */