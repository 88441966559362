@media only screen and (min-width: 768px) and (max-width: 992px) {
  .global-energy-banner-section {
    background: #000;
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .global-energy-banner-section {
    background: #000;
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
  }
}/*# sourceMappingURL=ResponsiveEaproGlobalEnergyBanner.css.map */