@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .knowledge-center-page-banner-section {
        .business-heading {
            position: relative;
            text-align: center;

            img {
                height: 100px;
                width: 50%;
            }

            .business-banner-heading {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(-0%, -50%);
                width: 100%;
                bottom: 0;
                h1 {
                    font-weight: 700;
                    font-size: 38px;
                }

                h3 {
                    font-weight: 700;
                    font-size: 22px;
                    width: 100%;
                }
            }

        }

        .banner-top-img-bg-rel {
            position: relative;

            .banner-top-content-absol {
                position: absolute;
                top: 80%;
                left: 0%;
                transform: translate(0%, -80%);
                width: 100%;
                bottom: 0;
            }
        }
    }
}

@media only screen and (min-width:321px) and (max-width:767px) {
    .knowledge-center-page-banner-section {
        .business-heading {
            position: relative;
            text-align: center;
            // margin-left: -1%;

            img {
                height: 100px;
                width: 50%;
            }

            .business-banner-heading {
                position: absolute;
                top: 80%;
                left: 0%;
                transform: translate(-0%, -80%);
                width: 100%;
                bottom: 0;

                h1 {
                    font-weight: 700;
                    font-size: 30px;
                }

                h3 {
                    font-weight: 700;
                    font-size: 20px;
                    width: 100%;
                }
            }

        }

        .banner-top-img-bg-rel {
            position: relative;

            .banner-top-content-absol {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width:100px) and (max-width:320px) {
    .knowledge-center-page-banner-section {
        .business-heading {
            position: relative;
            text-align: center;
            // margin-left: -1%;

            img {
                height: 100px;
                width: 50%;
            }

            .business-banner-heading {
                position: absolute;
                top: 80%;
                left: 0%;
                transform: translate(-0%, -80%);
                width: 100%;
                bottom: 0;

                h1 {
                    font-weight: 700;
                    font-size: 25px;
                }

                h3 {
                    font-weight: 700;
                    font-size: 18px;
                    width: 100%;
                }
            }

        }

        .banner-top-img-bg-rel {
            position: relative;

            .banner-top-content-absol {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
                width: 100%;
            }
        }
    }
}