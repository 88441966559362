.our-specialization-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/our-speciali-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.our-specialization-section .specialization-heading-dv h3 {
  font-weight: 700;
  font-size: 22px;
}
.our-specialization-section .specialization-image img {
  width: 100%;
}/*# sourceMappingURL=OurSpecialization.css.map */