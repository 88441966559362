.over-global-energy-enquiry-section {
  background: #000;
  background-image: url("../../../../public/images/global-footprints/ab-pattern-bg.png");
  background-position: center;
  background-size: cover;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.over-global-energy-enquiry-section .enquiry-heading {
  padding: 30px 0px;
}
.over-global-energy-enquiry-section .enquiry-heading h5 {
  font-weight: 700;
}
.over-global-energy-enquiry-section .enquiry-left-d {
  text-align: left;
}
.over-global-energy-enquiry-section .enquiry-left-d img {
  width: 70%;
}
.over-global-energy-enquiry-section .enquiry-form-right-dv .enq-submit-btn {
  text-align: left;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  border: 1px solid #000;
  padding: 5px 20px;
}
.over-global-energy-enquiry-section .react-international-phone-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
}/*# sourceMappingURL=EAPROOverseasForms.css.map */