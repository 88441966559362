.career-at-eapro-three-con-search-jobs {
  background-color: #f1f0f0;
  padding: 40px;
  margin-top: 40px;

  .search-job-btn {
    margin: 30px 0px 10px 0px;

    button {
      background-color: #000;
      color: #fff;
      padding: 8px 20px;
      border-radius: 5px;
      text-decoration: none;
    }
  }
}

.career-at-eapro-three-page-content-section {
  background: #000;
  background-image: url("../../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;

  .listing-parent-dv {
    .pagination-dv {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;

      nav {
        .pagination {
          .page-item {
            color: #000;

            .page-link {
              border: none;
              background-color: transparent;
              color: #000;
              font-weight: 700;

              svg {
                font-weight: bold;
              }
            }
          }

          .page-item.active {
            .page-link {
              border: none;
              background-color: transparent;
              color: #000;
              border: 1px solid #000;
            }
          }

          .previous {
            background: url("/public/left-arrow.png");
          }

          .next {
            background: url("/public/right-arrow.png");
          }

          /* Styles for the previous and next buttons */
          .previous,
          .next {
            display: inline-block;
            padding: 5px 10px;
            margin: 10px 5px;
            // border: 1px solid #ccc;
            border-radius: 4px;
            // background-color: #f0f0f0;
            color: black;
            text-decoration: none;
            cursor: pointer;
            background-size: contain;
            width: 2px;
            background-repeat: no-repeat;
          }

          .previous:hover,
          .next:hover {
            background-color: #e0e0e0;
          }

          /* Styles for active page number */
          .page-num.active {
            font-weight: bold;
            color: blue;
          }

          /* Styles for other page numbers */
          .page-num {
            display: inline-block;
            padding: 5px 8px;
            margin: 0 2px;
            // border: 1px solid #ccc;
            border-radius: 4px;
            // background-color: #fff;
            color: #333;
            text-decoration: none;
            font-weight: 900;
            font-size: 20px;
            cursor: pointer;
          }

          .page-num:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }

    .table-responsive {
      .table {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        tbody {
          tr {
            td {
              a {
                text-decoration: none;
                color: #000;
              }
            }
          }
        }
      }
    }

    .upload-btn-para-bottom {
      text-align: center;

      .unable-to-find {
        font-size: 17px;
      }

      .upload-your-dv {
        a {
          background-color: #000;
          color: #fff;
          padding: 8px 20px;
          text-decoration: none;
          border-radius: 5px;
        }
      }
    }
  }

  .job-apply-btn {
    background-color: #000;
    color: #fff !important;
    padding: 6px 18px;
    border-radius: 5px;
    text-decoration: none;

  }
}