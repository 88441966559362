.career-at-eapro-search-jobs {
    background-color: #F1F0F0;
    padding: 40px;
    margin-top: 40px;

    .search-job-btn {
        margin: 30px 0px 10px 0px;

        a {
            background-color: #000;
            color: #fff;
            padding: 8px 20px;
            border-radius: 5px;
            text-decoration: none;
        }
    }
}
.career-at-eapro-page-content-section {
   
    background: #000;
    background-image: url("../../../../../public/images/help-and-support/color-bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;
    background-attachment: fixed;

    .career-content-heading {
        h5 {
            font-weight: 700;
        }
    }

    .form-parent-custon-dv {
        background-color: #F1F0F0;
        padding: 40px;
        border-radius: 5px;
        margin: 20px 0px;

        .custom-row-form {
            text-align: left;

            input {
                &::placeholder {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            .submit-btn-custom {
                background-color: #000;
                color: #fff;
            }
        }

        .form-container {
            .upload-files-container {
                // background-color: #f7fff7;
                width: 100%;
                padding: 0px;
                border-radius: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                // box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
            }

            .drag-file-area {
                border: 2px dashed #7b2cbf;
                border-radius: 0px;
                margin: 0px;
                padding: 0px;
                width: 100%;
                text-align: center;
                background: #fff;
            }

            .drag-file-area .upload-icon {
                font-size: 50px;
            }

            .drag-file-area h3 {
                font-size: 18px;
                margin: 15px 0;
            }

            .drag-file-area label {
                font-size: 18px;
            }

            .drag-file-area label .browse-files-text {
                color: #7b2cbf;
                font-weight: bolder;
                cursor: pointer;
            }

            .browse-files span {
                position: relative;
                top: 0;
            }

            .default-file-input {
                opacity: 0;
                width: 100%;
            }

            .cannot-upload-message {
                background-color: #ffc6c4;
                font-size: 17px;
                display: flex;
                align-items: center;
                margin: 5px 0;
                padding: 5px 10px 5px 30px;
                border-radius: 5px;
                color: #BB0000;
                display: none;
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            .cannot-upload-message span,
            .upload-button-icon {
                padding-right: 10px;
            }

            .cannot-upload-message span:last-child {
                padding-left: 20px;
                cursor: pointer;
            }

            .file-block {
                color: #f7fff7;
                background-color: #7b2cbf;
                transition: all 1s;
                width: 100%;
                position: relative;
                display: none;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0 15px;
                padding: 10px 20px;
                border-radius: 25px;
                cursor: pointer;
            }

            .file-info {
                display: flex;
                align-items: center;
                font-size: 15px;
            }

            .file-icon {
                margin-right: 10px;
            }

            .file-name,
            .file-size {
                padding: 0 3px;
            }

            .remove-file-icon {
                cursor: pointer;
            }

            .progress-bar {
                display: flex;
                position: absolute;
                bottom: 0;
                left: 4.5%;
                width: 0;
                height: 5px;
                border-radius: 25px;
                background-color: #4BB543;
            }

            .upload-button {
                background-color: #7b2cbf;
                color: #f7fff7;
                display: flex;
                align-items: center;
                font-size: 18px;
                border: none;
                border-radius: 20px;
                margin: 10px;
                padding: 7.5px 50px;
                cursor: pointer;
            }
            .file-label-cus{
                border: 1px solid #ccc;
                padding: 10px;
                width: 100%;
                span{
                    color: #818181;
                    font-size: 14px;
                    font-style: italic;
                }
            }
            .upload-photograph-parent{
                position: relative;
                .icon-img{
                    position: absolute;
                    right: 5px;
                    top: 5px;
                }
            }
        }
    }

    .react-international-phone-input{
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-body-color);
        appearance: none;
        background-color: var(--bs-body-bg);
        background-clip: padding-box;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    
        border: none;
      
    }
    .react-international-phone-country-selector-button{
        // border: none;
    }

}