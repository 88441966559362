.achievements-component-section {
  background: #000;
  background-image: url("../../../../public/images/about-us/ab-pattern-bg.png");
  background-position: center;
  background-size: cover;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.achievements-component-section .achievements-component-heading-dv {
  padding-bottom: 30px;
}
.achievements-component-section .achievements-component-heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.achievements-component-section .achievement-award-right-dv-content {
  text-align: left;
}
.achievements-component-section .achievement-award-right-dv-content ul li {
  margin-bottom: 20px;
}
.achievements-component-section .achievement-award-left-dv-content {
  text-align: left;
}
.achievements-component-section .achievement-award-left-dv-content ul li {
  margin-bottom: 20px;
}/*# sourceMappingURL=AchievementsComponent.css.map */