
.quality-assurance-banner-sec {
    background: #000;
    // background-image: url("../../../../public/images/help-and-support/banner-bg.jpg");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    .business-heading-top{
        h3{
            font-weight: 700;
            font-size: 60px;
        }
    }
    .business-heading {
        position: relative;
        text-align: center;
       

        img {
            height: 60px;
        }

        h3 {
            font-weight: 700;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;

        }
    }
    .banner-top-img-bg-rel {
        position: relative;

        img.banner-img-tag {
            width: 100%;
        }

        .banner-top-content-absol {
            position: absolute;
            bottom: 15%;
            left: 0%;
            transform: translate(0%, -15%);
            width: 100%;
        }
    }
}

.quality-assurance-content-sec{
    .new-page-heading{
        h5{
           width: 42% 
        }
    }

}