@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .our-product-section {
        .product-card-row {
            .product-card-right {
                margin: 10px 0px;

                .product-image {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    margin: 15px auto;
                }
            }
            .product-card-items {
                padding: 7px;
                width:100% !important;
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .our-product-section {
        .product-card-row {
            .product-card-right {
                margin: 10px 0px;

                .product-image {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    margin: 15px auto;
                }
                .product-details-content {
                    p {
                        min-height: 140px;
                    }
                }
            }
            .product-card-items {
                padding: 7px;
                width:100% !important;
            }
        }
    }
}

@media only screen and (min-width:100px) and (max-width:320px) {}