.global-footprint-banner-section {
  background: #000;
  background-image: url("../../../../public/images/global-footprints/banner-bg.jpg");
  background-position: center;
  background-size: cover;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;

  // filter: grayscale(1);
  .store-heading {
    position: relative;
    text-align: center;

    img {
      height: 60px;
    }

    h5 {
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .banner-img-dv {
    width: 100%;
    text-align: center;
    padding: 30px 0px;

    img {
      width: 600px;
      height: 300px;
    }
  }
}
