@media only screen and (min-width: 768px) and (max-width: 992px) {
  .csr-details-banner-section {
    background: #000;
    background-position: center;
    background-size: 100% 100%;
    background-color: #f1f0f0;
    background-repeat: no-repeat;
    padding: 80px 0px;
    height: 300px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .csr-details-banner-section {
    background: #000;
    background-position: center;
    background-size: 100% 100%;
    background-color: #f1f0f0;
    background-repeat: no-repeat;
    padding: 80px 0px;
    height: 200px;
  }
}/*# sourceMappingURL=ResponsiveCsrDetailsBanner.css.map */