@media only screen and (max-width: 1201px) {
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .pillars-of-eapro-section {
    padding: 50px 0px 20px 0px;
    .piller-card-row {
      .piller-card-parent {
        border: 1px solid #d3a100;
        border-radius: 140px 140px 77px 77px;
        background-color: #fff;
        margin-bottom: 20px;
        .piller-img {
          width: 107%;
          margin-top: -30px;
          margin-left: -10px;
          margin-right: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .pillars-of-eapro-section {
    padding: 50px 0px 20px 0px;
    .piller-card-row {
      .piller-card-parent {
        border: 1px solid #d3a100;
        border-radius: 77px 77px;
        background-color: #fff;
        margin-bottom: 20px;
        .piller-img {
          width: 107%;
          margin-top: -20px;
          margin-left: -6px;
          margin-right: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 321px) and (max-width: 425px) {
  .pillars-of-eapro-section {
    .piller-card-row {
      .piller-card-parent {
        .piller-content {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 100px) and (max-width: 320px) {
  .pillars-of-eapro-section {
    .piller-card-row {
      .piller-card-parent {
        border: 1px solid #d3a100;
        border-radius: 77px 77px;
        background-color: #fff;
        margin-bottom: 50px;

        .piller-img {
          width: 107%;
          margin-top: -10px;
          margin-left: -4px;
          margin-right: auto;
        }
        .piller-content {
          height: 275px;
        }
      }
    }
  }
}
