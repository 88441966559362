.founder-md-section-parent {
    background: #000;
    background-image: url("../../../../public/images/about-us/ab-pattern-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding-bottom: 50px;

    // padding: 80px 0px;
    .founder-images-left-dv {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            background-color: #ffffff75;
            height: 94%;
            width: 102%;
            top: 35px;
            left: -6px;
            z-index: -1;
        }

        img {
            margin-top: -40px;
        }
    }

    .founder-images-right-dv {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            background-color: rgba(255, 255, 255, 0.4588235294);
            height: 94%;
            width: 107%;
            top: 50px;
            left: -16px;
            z-index: -1;
        }
    }

    .founder-right-content {
        text-align: left;
        padding: 30px 0px;

        .founder-name {
            h4 {
                margin-bottom: 0px;
                text-transform: uppercase;
                font-weight: 700;
            }

            p {
                margin-bottom: 0px;
            }

            h6 {
                font-weight: 700;
                color: #000;
                padding: 20px 0px;

                span {
                    color: #B70200;
                }
            }
        }

        .founder-content-p {
            p {
                font-size: 15px;
            }

            h6 {
                font-weight: 600;
            }
        }
    }
}

.founder-md-section-parent-new-design {
    background: #000;
    background-image: url("../../../../public/images/about-us/ab-pattern-bg.png");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .founder-images-left-dv {
        background: #fff;
        padding: 15px;
        border: 1px solid #FDBF05;
        img {
            width: 229px;
            height: 298px;
            object-fit: cover;
        }
    }

    .founder-images-right-dv {
        background: #fff;
        padding: 15px;
        border: 1px solid #FDBF05;

        img {
            width: 229px;
            height: 298px;
            object-fit: cover;
        }
    }

    .founder-name-post {
        a {
            text-decoration: none;
            color: #000;
        }

        margin: 10px 0px;

        p {
            font-size: 14px;
        }
    }
}