.about-export-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.about-export-section .why-heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.about-export-section .para-content p {
  text-align: justify;
}
.about-export-section .counter-row .card-box-counter {
  background-color: #000;
  padding: 30px 20px;
  color: #fff;
}
.about-export-section .counter-row .card-box-counter h4 {
  margin-bottom: 0px;
  font-weight: 700;
}
.about-export-section .counter-row .card-box-counter p {
  margin-bottom: 0px;
  font-size: 15px;
}
.about-export-section .counter-row .card-box-counter.bg-yellow {
  background-color: #FFC300;
  color: #000;
}/*# sourceMappingURL=AboutExpert.css.map */