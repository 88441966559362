.business-division-banner-section {
  background: #000;
  background-position: center;
  background-size: cover;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
}
.business-division-banner-section .business-heading-top h1 {
  font-weight: 700;
  font-size: 60px;
}
.business-division-banner-section .business-heading {
  position: relative;
  text-align: center;
}
.business-division-banner-section .business-heading img {
  height: 60px;
}
.business-division-banner-section .business-heading h1 {
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 55px;
}
.business-division-banner-section .banner-top-img-bg-rel {
  position: relative;
}
.business-division-banner-section .banner-top-img-bg-rel img.banner-img-tag {
  width: 100%;
}
.business-division-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
  position: absolute;
  top: 15%;
  left: 0%;
  transform: translate(0%, -15%);
  width: 100%;
}/*# sourceMappingURL=BussinessDivisionsBanner.css.map */