.solar-solution-page-details-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.solar-solution-page-details-section .career-content-heading h5 {
  font-weight: 700;
}
.solar-solution-page-details-section .power-solution-details p {
  text-align: justify;
}
.solar-solution-page-details-section .book-a-free-consultation {
  margin-top: 50px;
}
.solar-solution-page-details-section .book-a-free-consultation a {
  background-color: #000;
  color: #FFBC00;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 700;
}/*# sourceMappingURL=SolarSolutionPageDetails.css.map */