.solar-solution-page-banner-section {
    background: #000;
    // background-image: url("../../../../public/images/help-and-support/banner-bg.jpg");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;

    // padding: 80px 0px;
    // height: 400px;
    .business-heading {
        position: relative;
        text-align: center;
        // margin-left: -1%;

        img {
            height: 200px;
            width: 50%;
        }

        .business-banner-heading {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(0%, -50%);
            width: 100%;

            h1 {
                font-weight: 700;
                font-size: 40px;
            }

            h5 {
                font-weight: 700;
                width: 45%;
            }
        }

    }

    .banner-top-img-bg-rel {
        position: relative;

        img.banner-img-tag {
            width: 100%;
        }

        .banner-top-content-absol {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(0%, -50%);
            width: 100%;
        }
    }
}