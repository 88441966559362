.contact-us-content-section {
    background: #000;
    background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;
    background-attachment: fixed;

    .contact-parent-card {
        margin-bottom: 30px;

        .left-contact-card {
            border-radius: 10px;
            background: #F2F1F1;
            padding: 30px;
            height: 100%;

            .image-heading {
                h3 {
                    font-size: 22px;
                    font-weight: 700;
                    padding: 20px;
                }
            }

            .corporate-address-parent {
                .corporate-office-address {
                    h3 {
                        text-align: left;
                        font-size: 22px;
                        font-weight: 700;
                    }

                    p {
                        text-align: left;
                    }
                }

                .get-direction-btn {
                    .submit-btn-custom {
                        background-color: #000;
                        color: #FFBC00;
                    }
                }
            }

            .industries-address-parent {
                margin-top: 30px;

                .corporate-office-address {
                    h3 {
                        text-align: left;
                        font-size: 22px;
                        font-weight: 700;
                    }

                    p {
                        text-align: left;
                    }
                }

                .get-direction-btn {
                    .submit-btn-custom {
                        background-color: #000;
                        color: #FFBC00;
                    }
                }
            }

        }

        .right-contact-card {
            border-radius: 10px;
            background: #F2F1F1;
            padding: 30px;
            height: calc(50% - 12px);

            .image-heading {
                h3 {
                    font-size: 22px;
                    font-weight: 700;
                    padding: 20px;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }

            .image-heading1 {
                h3 {
                    font-size: 22px;
                    font-weight: 700;
                    padding: 20px;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }

    .help-support-heading {
        h5 {
            font-weight: 700;
        }
    }

    .help-support-content-dv {
        margin: 20px 0px;
    }

    .form-parent-custon-dv {
        text-align: left;
        border-radius: 16px;
        background: #F1F0F0;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;

        .custom-row-form {
            text-align: left;

            input {
                &::placeholder {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            select {
                font-size: 13px;
                color: #818181;
                font-style: italic;
            }

            .upload-product-front {
                border: 1px solid #ccc;
                padding: 5px;
                width: 100%;
                border-radius: 5px;
                background-color: #fff;
                position: relative;

                .upload-product-icon {
                    position: absolute;
                    right: 6px;
                    top: 4px;

                    img {
                        width: 25px;
                    }
                }

                span {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            .upload-product-back {
                border: 1px solid #ccc;
                padding: 5px;
                width: 100%;
                border-radius: 5px;
                background-color: #fff;
                position: relative;

                .upload-product-icon {
                    position: absolute;
                    right: 6px;
                    top: 4px;

                    img {
                        width: 25px;
                    }
                }

                span {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            .submit-btn-custom {
                background-color: #000;
                color: #FFBC00;
            }
        }
    }

    .map-two-div {
        padding-top: 40px;
        .help-support-heading {
            h5 {
                font-weight: 700;
            }
        }

        .map-dv-parent {
            h5{
                font-weight: 600;
            }
            iframe{
                border: 10px solid #ccc !important;
            }
        }
    }

    .react-international-phone-input{
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-body-color);
        appearance: none;
        background-color: var(--bs-body-bg);
        background-clip: padding-box;
        border: var(--bs-border-width) solid var(--bs-border-color);
        
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        border: none;
        
    }
    .react-international-phone-country-selector-button{
        border: none;
    }

}