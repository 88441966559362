.pillars-of-eapro-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/bg-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.pillars-of-eapro-section .piller-view-all-btn a {
  font-weight: 700;
  text-decoration: none;
  color: #000;
}
.pillars-of-eapro-section .specialization-heading-dv h3 {
  font-weight: 700;
  font-size: 22px;
}
.pillars-of-eapro-section .piller-card-row {
  margin: 40px 0px 20px 0px;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent {
  border: 1px solid #d3a100;
  border-radius: 90px 90px 77px 77px;
  background-color: #fff;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent .piller-img {
  width: 109%;
  margin-top: -26px;
  margin-left: -8px;
  margin-right: 0;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent .piller-img img {
  width: 100%;
  aspect-ratio: 2/2;
  -o-object-fit: contain;
     object-fit: contain;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent .piller-content {
  padding: 20px 10px;
  height: 265px;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent .piller-content h4 {
  color: #d3a100;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent .piller-content p {
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pillars-of-eapro-section .piller-card-row .piller-card-parent .piller-content .piller-read-more {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}
.pillars-of-eapro-section .piller-card-row .piller-items {
  padding: 40px 10px;
}/*# sourceMappingURL=PillarsOfEAPRO.css.map */