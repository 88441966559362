.help-and-support-content-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.help-and-support-content-section .help-support-heading h5 {
  font-weight: 700;
}
.help-and-support-content-section .help-support-content-dv {
  margin: 20px 0px;
}
.help-and-support-content-section .help-support-content-dv p {
  word-break: break-all;
}
.help-and-support-content-section .services-card-parent-yellow {
  background-color: #FFC504;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0px;
  height: 100%;
}
.help-and-support-content-section .services-card-parent-yellow .services-images {
  width: 60px;
  text-align: center;
  margin: 0 auto;
}
.help-and-support-content-section .services-card-parent-yellow .services-heading {
  padding: 10px 0px;
}
.help-and-support-content-section .services-card-parent-yellow .services-heading p {
  margin-bottom: 0px;
}
.help-and-support-content-section .services-card-parent-black {
  background-color: #000;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0px;
  height: 100%;
}
.help-and-support-content-section .services-card-parent-black .services-images {
  width: 60px;
  text-align: center;
  margin: 0 auto;
}
.help-and-support-content-section .services-card-parent-black .services-heading {
  padding: 10px 0px;
  color: #fff;
}
.help-and-support-content-section .services-card-parent-black .services-heading p {
  margin-bottom: 0px;
}
.help-and-support-content-section .help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.help-and-support-content-section .help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}

.help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}/*# sourceMappingURL=HelpAndSupportContent.css.map */