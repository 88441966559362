@media only screen and (min-width: 768px) and (max-width: 992px) {
  .our-product-section .product-card-row .product-card-right {
    margin: 10px 0px;
  }
  .our-product-section .product-card-row .product-card-right .product-image {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 15px auto;
  }
  .our-product-section .product-card-row .product-card-items {
    padding: 7px;
    width: 100% !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .our-product-section .product-card-row .product-card-right {
    margin: 10px 0px;
  }
  .our-product-section .product-card-row .product-card-right .product-image {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 15px auto;
  }
  .our-product-section .product-card-row .product-card-right .product-details-content p {
    min-height: 140px;
  }
  .our-product-section .product-card-row .product-card-items {
    padding: 7px;
    width: 100% !important;
  }
}/*# sourceMappingURL=ResponsiveOurProductSection.css.map */