.solar-searis-product-details-section {
  position: relative;
  padding: 50px 0px;
  overflow: hidden;
  // .why-eapro-section {
  background: rgba(204, 204, 204, 0.3607843137);
  // background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-image: url("../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  background-attachment: fixed;


  // <!-- graphic-1  -->
  .bg-graphic-right-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(25%, -10%);

    img {
      width: 80%
    }
  }

  // <!-- graphic-2  -->
  .bg-graphic-left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translate(0, 10%);

    img {
      width: 80%;
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 25px;
  }

  // <!-- ---- Product Item ----- -->
  .product-item-img {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
    height: 100%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // margin-top: auto;
      // margin-bottom: auto;
    }
  }

  .product-item-info {
    h2 {
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 24px;
    }

    .key-features {
      h6 {
        font-weight: 400;
        margin-bottom: 20px;
      }

      ul {
        padding-left: 1rem;

        li {
          font-size: 14px;
        }
      }
    }

    .model {
      margin-bottom: 20px;

      h6 {
        font-weight: 400;
        margin-bottom: 10px;
        font-size: 15px;
      }

      h5 {
        font-weight: 600;
        font-size: 15px;
      }
    }

    .capacity {
      h6 {
        font-weight: 400;
      }
    }

    .view-product-btn {
      color: #000;
      background-color: #fdbf05;
      border: none;
      border-radius: 10px;
      text-transform: capitalize;
      padding: 4px 30px;
      font-weight: 500;
      text-decoration: none;
    }
  }
}