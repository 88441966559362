.what-eapro-team-mate-slider-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../../public/images/bg-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px 0px 0px;
}
.what-eapro-team-mate-slider-section .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.what-eapro-team-mate-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-card-parent {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  background-color: #fff;
  margin: 10px 0px;
  position: relative;
}
.what-eapro-team-mate-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-card-parent img {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  border: 4px solid #000;
  margin: 0px auto;
  position: absolute;
  background: #e5e4e4;
  top: -100px;
  left: 0;
  right: 0;
}
.what-eapro-team-mate-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-card-parent .card-content-parent {
  padding: 20px 0px;
  padding-top: 130px;
}
.what-eapro-team-mate-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-card-parent .card-content-parent .star-bottom svg {
  margin: 0px 5px;
}
.what-eapro-team-mate-slider-section .footer-view-all-btn a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.what-eapro-team-mate-slider-section .slick-next {
  background: url("../../../../../public/images/team-right.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
}
.what-eapro-team-mate-slider-section .slick-next::before {
  display: none;
}
.what-eapro-team-mate-slider-section .slick-prev {
  background: url("../../../../../public/images/team-left.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
}
.what-eapro-team-mate-slider-section .slick-prev::before {
  display: none;
}
.what-eapro-team-mate-slider-section .slick-slider {
  width: 100%;
  background-color: transparent;
  padding: 50px 60px;
}
.what-eapro-team-mate-slider-section .slick-slider .slick-slide {
  margin: 85px 0px 0px 0px;
  padding: 20px 10px;
  transition: all 0.5s ease-in-out;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .what-eapro-team-mate-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-card-parent .card-content-parent {
    padding: 20px 0px;
    padding-top: 130px;
  }
  .what-eapro-team-mate-slider-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-card-parent .card-content-parent p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}/*# sourceMappingURL=WhatEaproTeamMateSaySlider.css.map */