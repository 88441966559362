@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
        .career-banner-section {
                background: #000;
                background-image: url("../../../../public/images/career/banner-bg.jpg");
                background-position: center;
                background-size: 100% 100%;
                background-color: #F1F0F0;
                background-repeat: no-repeat;
                padding: 80px 0px;
                height: 300px;
        }
}

@media only screen and (min-width:200px) and (max-width:767px) {
        .career-banner-section {
                background: #000;
                background-image: url("../../../../public/images/career/banner-bg.jpg");
                background-position: center;
                background-size: 100% 100%;
                background-color: #F1F0F0;
                background-repeat: no-repeat;
                padding: 80px 0px;
                height: 200px;
        }
}