.about-banner-parent-section {
  background: #cccccc5c;
  background-image: url('../../../../public/images/about-us/about-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;

  .specialization-heading-dv {
    h5 {
      font-weight: 700;
      font-size: 22px;
    }
  }
  .year-of-dv-parent {
  }
  .years-of-trust-content {
    text-align: left;
    h1 {
      font-size: 35px;
      font-weight: 700;
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .about-banner-parent-section {
    .years-of-trust-content {
      text-align: justify;
      h1 {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
