@media only screen and (min-width: 1400px) and (max-width: 1920px) {
  .navbar-second-section .navbar-collapse .navbar-nav-second-ul {
    margin-left: 70px;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .navbar-second-section .navbar-collapse .navbar-nav li.nav-item a.nav-link {
    font-size: 14px;
    padding: 0px 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .navbar-second-section .navbar-collapse .navbar-nav li.nav-item a.nav-link {
    font-size: 14px;
    padding: 0px 5px;
  }
}/*# sourceMappingURL=ResponsiveNavbar.css.map */