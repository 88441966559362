.pillar-videos-bblogs-element-section {
  background: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0px;
}
.pillar-videos-bblogs-element-section .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .nav-tabs {
  border: none;
  justify-content: center;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .nav-tabs .nav-item .nav-link {
  font-weight: 700;
  font-size: 16px;
  color: #000;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .nav-tabs .nav-item .nav-link:hover {
  border: none;
  box-shadow: none;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .nav-tabs .nav-item .nav-link.blogs-btn {
  position: relative;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .nav-tabs .nav-item .nav-link.blogs-btn::before {
  position: absolute;
  height: 27px;
  content: "";
  width: 2px;
  border: 1px solid #ccc;
  background-color: red;
  left: 3px;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .nav-tabs .nav-item .nav-link.active {
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: unset;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv {
  border: 4px solid #f1f0f0;
  border-radius: 10px;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img {
  margin-bottom: -10px;
  position: relative;
  background-color: #f1f0f0;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-img .play-btn-youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-heading {
  background-color: #f1f0f0;
  color: #000;
  padding: 10px 20px;
  text-align: left;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-heading h4 {
  font-size: 22px;
  font-weight: 500;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-heading p {
  margin-bottom: 0px;
  font-size: 13px;
}
.pillar-videos-bblogs-element-section .heading-tabs-parent .slider-parent-dv .slider-item-dv .slider-item-heading p a {
  color: #000;
  text-decoration: none;
}
.pillar-videos-bblogs-element-section .footer-view-all-btn a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.pillar-videos-bblogs-element-section .slick-next {
  background: url("../../../../public/images/right.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
  z-index: 100;
}
.pillar-videos-bblogs-element-section .slick-next::before {
  display: none;
}
.pillar-videos-bblogs-element-section .slick-prev {
  background: url("../../../../public/images/left.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
  z-index: 100;
}
.pillar-videos-bblogs-element-section .slick-prev::before {
  display: none;
}
.pillar-videos-bblogs-element-section .slick-slider {
  width: 100%;
  background-color: transparent;
}
.pillar-videos-bblogs-element-section .slick-slider .slick-slide {
  margin: 30px 0px;
  padding: 20px 10px;
  transition: all 0.5s ease-in-out;
}/*# sourceMappingURL=PillarVideos.css.map */