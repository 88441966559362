.knowledge-center-page-video-tuto-slider-section {
    background: #fff;
    // background-image: url("../../../../public/images/bg-banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0px;

    .heading-dv {
        h5 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .heading-tabs-parent {
        .nav-tabs {
            border: none;
            justify-content: center;

            .nav-item {
                .nav-link {
                    font-weight: 700;
                    font-size: 16px;
                    color: #000;

                    &:hover {
                        border: none;
                        box-shadow: none;
                    }

                }

                .nav-link.blogs-btn {
                    position: relative;

                    &::before {
                        position: absolute;
                        height: 27px;
                        content: "";
                        width: 2px;
                        border: 1px solid #ccc;
                        background-color: red;
                        left: 3px;
                    }

                }

                .nav-link.active {
                    border: none;
                    border-bottom: 2px solid #ccc;
                    background-color: unset;

                }
            }
        }

        .slider-parent-dv {
            .slider-item-dv {

                .slider-item-img {
                    margin-bottom: 10px;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .play-btn-youtube {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .slider-item-heading {
                    background-color: #000;
                    color: #fff;
                    padding: 10px 5px;
                    text-align: left;

                    h4 {
                        font-size: 22px;
                        font-weight: 500;
                    }

                    .p {
                        margin-bottom: 0px;
                        font-size: 13px;

                        a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }

                }
            }
        }
    }

    .footer-view-all-btn {
        a {
            text-decoration: none;
            color: #000;
            font-size: 16px;
        }
    }

    .slick-next {
        background: url('../../../../public/images/right.png')center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
        right: -70px;

        &::before {
            display: none;
        }
    }

    .slick-prev {
        background: url('../../../../public/images/left.png')center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
        left: -70px;

        &::before {
            display: none;
        }
    }

    .slick-slider {
        width: 100%;
        background-color: transparent;

        .slick-slide {
            margin: 30px 0px;
            padding: 20px 10px;
            transition: all 0.5s ease-in-out;

            &.slick-current,
            &.slick-center {
                .slider-item-dv {}

            }
        }
    }
}

.knowledge-centre-page-news-element-section {
    background: #fff;
    // background-image: url("../../../../public/images/bg-banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0px;

    .heading-dv {
        h5 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .heading-tabs-parent {
        .nav-tabs {
            border: none;
            justify-content: center;

            .nav-item {
                .nav-link {
                    font-weight: 700;
                    font-size: 16px;
                    color: #000;

                    &:hover {
                        border: none;
                        box-shadow: none;
                    }

                }

                .nav-link.blogs-btn {
                    position: relative;

                    &::before {
                        position: absolute;
                        height: 27px;
                        content: "";
                        width: 2px;
                        border: 1px solid #ccc;
                        background-color: red;
                        left: 3px;
                    }

                }

                .nav-link.active {
                    border: none;
                    border-bottom: 2px solid #ccc;
                    background-color: unset;

                }
            }
        }

        .slider-parent-dv {
            .slider-item-dv {
                border: 4px solid #F1F0F0;
                border-radius: 10px;

                .slider-item-img {
                    margin-bottom: -10px;
                    position: relative;
                    background-color: #F1F0F0;

                    img {
                        height: 300px;
                        width: 100%;
                        border-radius: 10px;
                    }

                    .play-btn-youtube {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .slider-item-heading {
                    background-color: #F1F0F0;
                    color: #000;
                    padding: 10px 20px;
                    text-align: left;

                    h4 {
                        font-size: 22px;
                        font-weight: 500;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 13px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        a {
                            color: #000;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .footer-view-all-btn {
        a {
            text-decoration: none;
            color: #000;
            font-size: 16px;
        }
    }

    .slick-next {
        background: url('../../../../public/images/right.png')center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
        right: -70px;

        &::before {
            display: none;
        }
    }

    .slick-prev {
        background: url('../../../../public/images/left.png')center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
        left: -70px;

        &::before {
            display: none;
        }
    }

    .slick-slider {
        width: 100%;
        background-color: transparent;

        .slick-slide {
            margin: 30px 0px;
            padding: 20px 10px;
            transition: all 0.5s ease-in-out;

            &.slick-current,
            &.slick-center {
                .slider-item-dv {}

            }
        }
    }
}

.knowledge-centre-page-bblogs-element-section {
    background: #fff;
    // background-image: url("../../../../public/images/bg-banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0px;

    .heading-dv {
        h5 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .heading-tabs-parent {
        .nav-tabs {
            border: none;
            justify-content: center;

            .nav-item {
                .nav-link {
                    font-weight: 700;
                    font-size: 16px;
                    color: #000;

                    &:hover {
                        border: none;
                        box-shadow: none;
                    }

                }

                .nav-link.blogs-btn {
                    position: relative;

                    &::before {
                        position: absolute;
                        height: 27px;
                        content: "";
                        width: 2px;
                        border: 1px solid #ccc;
                        background-color: red;
                        left: 3px;
                    }

                }

                .nav-link.active {
                    border: none;
                    border-bottom: 2px solid #ccc;
                    background-color: unset;

                }
            }
        }

        .slider-parent-dv {
            .slider-item-dv {
                border: 4px solid #F1F0F0;
                border-radius: 10px;

                .slider-item-img {
                    margin-bottom: -10px;
                    position: relative;
                    background-color: #F1F0F0;

                    .play-btn-youtube {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .slider-item-heading {
                    background-color: #F1F0F0;
                    color: #000;
                    padding: 10px 20px;
                    text-align: left;
                    height: 190px;

                    h4 {
                        font-size: 22px;
                        font-weight: 500;
                    }

                    h5 {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 13px;

                        a {
                            color: #000;
                            text-decoration: none;
                        }
                    }

                    .blog-custom-div-scroll {
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        height: 75px;
                    }
                }
            }
        }
    }

    .footer-view-all-btn {
        a {
            text-decoration: none;
            color: #000;
            font-size: 16px;
        }
    }

    .slick-next {
        background: url('../../../../public/images/right.png')center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
        right: -70px;

        &::before {
            display: none;
        }
    }

    .slick-prev {
        background: url('../../../../public/images/left.png')center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
        left: -70px;

        &::before {
            display: none;
        }
    }

    .slick-slider {
        width: 100%;
        background-color: transparent;

        .slick-slide {
            margin: 30px 0px;
            padding: 20px 10px;
            transition: all 0.5s ease-in-out;

            &.slick-current,
            &.slick-center {
                .slider-item-dv {}

            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .knowledge-center-page-video-tuto-slider-section .slick-prev {
        background: url("../../../../public/images/small-left.png") center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
    }

    .knowledge-center-page-video-tuto-slider-section .slick-next {
        background: url("../../../../public/images/small-right.png") center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
    }

    .knowledge-centre-page-bblogs-element-section .slick-next {
        background: url("../../../../public/images/small-right.png") center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
    }

    .knowledge-centre-page-bblogs-element-section .slick-prev {
        background: url("../../../../public/images/small-left.png") center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
    }

    .knowledge-centre-page-news-element-section .slick-next {
        background: url("../../../../public/images/small-right.png") center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
    }

    .knowledge-centre-page-news-element-section .slick-prev {
        background: url("../../../../public/images/small-left.png") center center no-repeat !important;
        width: 40px;
        height: 65px;
        z-index: 100;
    }
}