.contact-us-page-new-banner-section{
    background: #000;
    background-image: url("../../../../public/images/contact-us/banner-bg.png");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 0px 0px;

    .banner-images-left {
        img {
            width: 90%;
        }
    }

    .business-heading-top {
        h5 {
            font-weight: 400;
            font-size: 20px;
            text-align: left;
        }
    }

    .business-heading {
        position: relative;
        text-align: center;

        // img {
        //     height: 60px;
        // }

        h1 {
            font-weight: 700;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            font-size: 50px;
            text-align: left;

        }
    }

}