.load-calculator .form-ui {
  background-color: white;
  box-shadow: -1px 1px 5px 5px rgba(133, 128, 128, 0.75);
  margin: 30px 0px;
}
.load-calculator .form-ui .heading .count-img {
  width: 57px;
  height: 57px;
  background-size: contain;
}
.load-calculator .form-ui .heading span {
  font-size: large;
}
.load-calculator .form-ui .accordion-outer-box {
  margin-top: 10px;
  margin-bottom: 10px;
}
.load-calculator .form-ui .accordion-button {
  background-color: #212529;
  color: white;
}
.load-calculator .form-ui .accordion-button::after {
  color: white;
}
.load-calculator .form-ui .accordion-body-box {
  background-color: white;
}
.load-calculator .form-ui .accordion-body-box .accordion-body {
  overflow-x: scroll;
}
.load-calculator .form-ui .accordion-body-box .accordion-body::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.load-calculator .form-ui .accordion-body-box .accordion-body::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.load-calculator .form-ui .accordion-body-box .accordion-body::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
}
.load-calculator .form-ui .accordion-button::after {
  content: url("/public/images/minus-sign.png");
  width: 1.25rem;
  height: 1.25rem;
}
.load-calculator .form-ui .accordion-button.collapsed::after {
  content: url("/public/images/plus-sign.png");
}/*# sourceMappingURL=LoadCalculator.css.map */