.why-eapro-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  background-attachment: fixed;
}
.why-eapro-section .why-heading-dv h2 {
  font-weight: 700;
  font-size: 22px;
}
.why-eapro-section .counter-row .card-box-counter {
  background-color: #000;
  padding: 30px 20px;
  color: #fff;
}
.why-eapro-section .counter-row .card-box-counter h4 {
  margin-bottom: 0px;
  font-weight: 700;
}
.why-eapro-section .counter-row .card-box-counter p {
  margin-bottom: 0px;
  font-size: 15px;
}
.why-eapro-section .counter-row .card-box-counter.bg-yellow {
  background-color: #FFC300;
  color: #000;
}/*# sourceMappingURL=WhyEapro.css.map */