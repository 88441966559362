.vision-mission-value-section {
  background: #000;
  background-image: url('../../../../public/images/about-us/color-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0px;

  .vision-mission-value-dv {
    padding: 20px;
    .v-m-v-img {
    }
    .v-m-v-heading {
      h4 {
        font-size: 22px;
        font-weight: 700;
        span {
          color: #ffc300;
        }
      }
    }
    .v-m-v-content {
      text-align: center;
      p {
        height: 180px;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          border: none;
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #000000;
        }
      }
    }
  }
}
