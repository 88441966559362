.know-more-about-power-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.know-more-about-power-section .heading-dv h3 {
  font-weight: 700;
  font-size: 22px;
}
.know-more-about-power-section .slick-next {
  background: url("../../../../public/images/right.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
}
.know-more-about-power-section .slick-next::before {
  display: none;
}
.know-more-about-power-section .slick-prev {
  background: url("../../../../public/images/left.png") center center no-repeat !important;
  width: 40px;
  height: 65px;
}
.know-more-about-power-section .slick-prev::before {
  display: none;
}
.know-more-about-power-section .slick-slider {
  width: 100%;
  background-color: transparent;
}
.know-more-about-power-section .slick-slider .slick-slide {
  margin: 30px 0px;
  padding: 20px 50px;
  transition: all 0.5s ease-in-out;
}
.know-more-about-power-section .slick-slider .slick-slide.slick-current .slider-item-dv, .know-more-about-power-section .slick-slider .slick-slide.slick-center .slider-item-dv {
  background: #FFC300;
  border-radius: 5px;
}
.know-more-about-power-section .slick-slider .slick-slide.slick-current,
.know-more-about-power-section .slick-slider .slick-slide.slick-center {
  transform: scale(1.05);
  padding: 0px 15px;
}
.know-more-about-power-section .slider-parent-dv {
  padding: 50px 60px;
  height: 380px;
}
.know-more-about-power-section .slider-parent-dv .slider-item-dv {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
}
.know-more-about-power-section .slider-parent-dv .slider-item-dv .slider-item-heading h4 {
  font-size: 16px;
  font-weight: 700;
}/*# sourceMappingURL=KnowMoreAboutPower.css.map */