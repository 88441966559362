.footer-section {
  background-color: #000;
}
.footer-section .footer-inner-dv {
  padding: 30px 10px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-heading h3 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: left;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content ul {
  list-style: none;
  text-align: left;
  padding-left: 0px;
  margin-bottom: 0px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content ul li {
  margin-bottom: 10px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .form-input-subscribe {
  position: relative;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .form-input-subscribe input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 5px;
  border: none;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .form-input-subscribe input[placeholder] {
  font-size: 13px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .form-input-subscribe .at-rt-dv {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(-10%, -50%);
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .subscribe-btn .subs-btn-int {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  padding: 5px 5px;
  font-weight: 600;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .mail-strip {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .mail-strip p {
  padding: 5px 5px;
  margin-bottom: 0px;
  text-align: left;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .mail-strip p a {
  text-decoration: none;
  font-weight: 600;
  color: #000;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .mail-strip p a img {
  width: 20px;
  margin-right: 10px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .whatsapp-strip {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .whatsapp-strip p {
  padding: 5px 5px;
  margin-bottom: 0px;
  text-align: left;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .whatsapp-strip p a {
  text-decoration: none;
  font-weight: 600;
  color: #000;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .whatsapp-strip p a img {
  width: 18px;
  margin-right: 10px;
  margin-top: -3px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .follow-us-ul ul {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .follow-us-ul ul li {
  margin-right: 5px;
}
.footer-section .footer-inner-dv .footer-content-parent .footer-content .follow-us-ul ul li a i {
  color: #000;
  color: #000;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
}
.footer-section .footer-inner-dv .copy-right-div {
  padding: 30px 0px 10px 0px;
}
.footer-section .footer-inner-dv .copy-right-div p {
  margin-bottom: 0px;
  color: #fff;
  font-weight: 600;
}/*# sourceMappingURL=Footer.css.map */