.e-catalogue-banner-page-banner-section {
  background: #000;
  background-position: center;
  background-size: cover;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
}
.e-catalogue-banner-page-banner-section .business-heading {
  position: relative;
  text-align: center;
}
.e-catalogue-banner-page-banner-section .business-heading img {
  height: 100px;
  width: 50%;
}
.e-catalogue-banner-page-banner-section .business-heading .business-banner-heading {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  width: 100%;
}
.e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h1 {
  font-weight: 700;
  font-size: 40px;
}
.e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h5 {
  font-weight: 700;
  width: 45%;
}
.e-catalogue-banner-page-banner-section .banner-top-img-bg-rel-parent .banner-top-img-bg-rel {
  position: relative;
  text-align: center;
}
.e-catalogue-banner-page-banner-section .banner-top-img-bg-rel-parent .banner-top-img-bg-rel img.banner-img-tag {
  width: 100%;
}
.e-catalogue-banner-page-banner-section .banner-top-img-bg-rel-parent .banner-top-img-bg-rel .banner-top-content-absol {
  position: absolute;
  top: 80%;
  left: 0%;
  transform: translate(0%, -80%);
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .e-catalogue-banner-page-banner-section .business-heading {
    position: relative;
    text-align: center;
  }
  .e-catalogue-banner-page-banner-section .business-heading img {
    height: 100px;
    width: 50%;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h1 {
    font-weight: 700;
    font-size: 28px;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 20px;
    width: 100%;
  }
  .e-catalogue-banner-page-banner-section .banner-top-img-bg-rel {
    position: relative;
  }
  .e-catalogue-banner-page-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 80%;
    left: 0%;
    transform: translate(0%, -80%);
    width: 100%;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .e-catalogue-banner-page-banner-section .business-heading {
    position: relative;
    text-align: center;
  }
  .e-catalogue-banner-page-banner-section .business-heading img {
    height: 100px;
    width: 50%;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h1 {
    font-weight: 700;
    font-size: 20px;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 16px;
    width: 100%;
  }
  .e-catalogue-banner-page-banner-section .banner-top-img-bg-rel {
    position: relative;
  }
  .e-catalogue-banner-page-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .e-catalogue-banner-page-banner-section .business-heading {
    position: relative;
    text-align: center;
  }
  .e-catalogue-banner-page-banner-section .business-heading img {
    height: 100px;
    width: 50%;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h1 {
    font-weight: 700;
    font-size: 16px;
  }
  .e-catalogue-banner-page-banner-section .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 14px;
    width: 100%;
  }
  .e-catalogue-banner-page-banner-section .banner-top-img-bg-rel {
    position: relative;
  }
  .e-catalogue-banner-page-banner-section .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
}/*# sourceMappingURL=ECatalogueBannerNew.css.map */