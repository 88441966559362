@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .our-specialization-section{
        background: #cccccc5c;
        background-image: url("../../../../public/images/our-speciali-bg.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 50px 0px;
        .specialization-heading-dv{
            h5{
                font-weight: 700;
            }
        }
        .specialization-image{
            img{
                // height: 85vh;
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width:200px) and (max-width:767px) {
    .our-specialization-section{
        background: #cccccc5c;
        background-image: url("../../../../public/images/our-speciali-bg.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 50px 0px;
        .specialization-heading-dv{
            h5{
                font-weight: 700;
            }
        }
        .specialization-image{
            img{
                // height: 85vh;
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width:100px) and (max-width:320px) {
    .our-specialization-section{
        background: #cccccc5c;
        background-image: url("../../../../public/images/our-speciali-bg.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 50px 0px;
        .specialization-heading-dv{
            h5{
                font-weight: 700;
            }
        }
        .specialization-image{
            img{
                // height: 85vh;
                width: 100%;
            }
        }
    }
}