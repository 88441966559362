.footer-section {
    background-color: #000;

    .footer-inner-dv {
        padding: 30px 10px;

        .footer-content-parent {
            .footer-heading {
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                    text-align: left;
                }
            }

            .footer-content {
                ul {
                    list-style: none;
                    text-align: left;
                    padding-left: 0px;
                    margin-bottom: 0px;

                    li {
                        // line-height: 35px;
                        margin-bottom: 10px;

                        a {
                            text-decoration: none;
                            color: #fff;
                            font-size: 15px;
                        }
                    }
                }

                .form-input-subscribe {
                    position: relative;

                    input {
                        width: 100%;
                        padding: 10px 10px 10px 40px;
                        border-radius: 5px;
                        border: none;
                    }

                    input[placeholder] {
                        font-size: 13px;
                    }

                    .at-rt-dv {
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translate(-10%, -50%);
                    }

                }

                .subscribe-btn {
                    .subs-btn-int {
                        width: 100%;
                        margin-top: 10px;
                        border-radius: 5px;
                        border: none;
                        padding: 5px 5px;
                        font-weight: 600;
                    }
                }
                .mail-strip{
                    background-color: #fff;
                    border-radius: 5px;
                    margin-top: 20px;
                    p{
                        padding: 5px 5px;
                        margin-bottom: 0px;
                        text-align: left;
                        a{
                            text-decoration: none;
                            font-weight: 600;
                            color: #000;
                            img{
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .whatsapp-strip{
                    background-color: #fff;
                    border-radius: 5px;
                    margin-top: 20px;
                    p{
                        padding: 5px 5px;
                        margin-bottom: 0px;
                        text-align: left;
                        a{
                            text-decoration: none;
                            font-weight: 600;
                            color: #000;
                            img{
                                width: 18px;
                                margin-right: 10px;
                                margin-top: -3px;
                            }
                        }
                    }
                }
                .follow-us-ul{
                    ul{
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        justify-content: flex-start;
                        align-items: center;
                        li{
                            margin-right: 5px;
                            a{
                                // background-color: #fff;
                                i{
                                    color: #000;
                                    color: #000;
                                    background: #fff;
                                    padding: 10px;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .copy-right-div{
            padding: 30px 0px 10px 0px;
            p{
                margin-bottom: 0px;
                color: #fff;
                font-weight: 600;
            }
        }
    }
}