.design-solar-power-load-calculator-section {
    .design-before-row-dv {
        // background-image: url("../../../../public/images/bg.jpg");
        padding: 80px 0px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;


        .content-parent-dv {
            .content-inner-first {
                h4 {
                    color: #fff;
                    font-weight: 700;
                    font-size: 30px;
                }

                h5 {
                    color: #fff;
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .content-inner-second {
                .second-inner-button {
                    background-color: #FFC300;
                    border-radius: 5px;
                    padding: 10px 5px;
                    height: 100%;
                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;

                        img {
                            width: 30px;
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
}