.our-specialization-section{
    background: #cccccc5c;
    background-image: url("../../../../public/images/our-speciali-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;
    .specialization-heading-dv{
        h3{
            font-weight: 700;
            font-size: 22px;
        }
    }
    .specialization-image{
        img{
            // height: 85vh;
            width: 100%;
        }
    }
}