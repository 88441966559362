.power-product-custom-product-1 {
  position: relative;
  padding: 50px 0px;
  overflow: hidden;
  text-align: left;
}
.power-product-custom-product-1 .bg-graphic-right-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transform: translate(25%, -10%);
}
.power-product-custom-product-1 .bg-graphic-right-top img {
  width: 80%;
}
.power-product-custom-product-1 .bg-graphic-left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translate(0, 10%);
}
.power-product-custom-product-1 .bg-graphic-left-bottom img {
  width: 80%;
}
.power-product-custom-product-1 h4 {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 20px;
  text-shadow: 1px 2px #b7aeae;
}
.power-product-custom-product-1 .product-img {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
  height: 100%;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.power-product-custom-product-1 .product-info h2 {
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.power-product-custom-product-1 .product-info .view-all-btn {
  margin-top: 50px;
  color: #fdbf05;
  background-color: #000;
  border: none;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 5px 10px;
  font-weight: 500;
  text-decoration: none;
}

.power-product-custom-product-2 {
  position: relative;
  padding: 50px 0px;
  background-color: #F1F0F0;
  overflow: hidden;
  z-index: 10;
  text-align: left;
}
.power-product-custom-product-2 .bg-graphic-center {
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  z-index: -1;
}
.power-product-custom-product-2 h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.power-product-custom-product-2 .product-img {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
  height: 100%;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.power-product-custom-product-2 .product-info h2 {
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.power-product-custom-product-2 .product-info .view-all-btn {
  margin-top: 50px;
  color: #000;
  background-color: #fdbf05;
  border: none;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 5px 10px;
  font-weight: 500;
  text-decoration: none;
}

.power-product-custom-product-3 {
  position: relative;
  padding: 50px 0px;
  overflow: hidden;
  text-align: left;
}
.power-product-custom-product-3 .bg-graphic-right-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transform: translate(25%, -10%);
}
.power-product-custom-product-3 .bg-graphic-right-top img {
  width: 80%;
}
.power-product-custom-product-3 .bg-graphic-left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translate(0, 10%);
}
.power-product-custom-product-3 .bg-graphic-left-bottom img {
  width: 80%;
}
.power-product-custom-product-3 h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.power-product-custom-product-3 .product-img {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
  height: 100%;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.power-product-custom-product-3 .product-info h2 {
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.power-product-custom-product-3 .product-info .view-all-btn {
  margin-top: 50px;
  color: #000;
  background-color: #fdbf05;
  border: none;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 5px 10px;
  font-weight: 500;
  text-decoration: none;
}/*# sourceMappingURL=ViewProductDetailsContent.css.map */