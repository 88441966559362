.purchase-amc-page-1-content-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.purchase-amc-page-1-content-section .help-support-heading h5 {
  font-weight: 700;
}
.purchase-amc-page-1-content-section .help-support-content-dv {
  margin: 20px 0px;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv {
  text-align: left;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form {
  text-align: left;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form input::-moz-placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form input::placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form select {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-front {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-front .upload-product-icon {
  position: absolute;
  right: 6px;
  top: 4px;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-front .upload-product-icon img {
  width: 25px;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-front span {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-back {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-back .upload-product-icon {
  position: absolute;
  right: 6px;
  top: 4px;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-back .upload-product-icon img {
  width: 25px;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .upload-product-back span {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.purchase-amc-page-1-content-section .form-parent-custon-dv .custom-row-form .submit-btn-custom {
  background-color: #000;
  color: #FFBC00;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.purchase-amc-page-1-content-section .help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}

.help-and-support-section-inner {
  padding: 50px 0px;
  background-color: transparent;
}
.help-and-support-section-inner .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section-inner .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}/*# sourceMappingURL=PurchaseAMCPage1Content.css.map */