.learnMore-page-content-section {
    background: #000;
    background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;
    background-attachment: fixed;

    .blog-nav-tabs {
        border-bottom: 1px solid transparent;
        justify-content: center;
        padding: 50px 0px;

        .nav-item {
            margin: 0px 10px;

            .nav-link.active {
                color: #000;
                background-color: transparent;
                border-color: #000;
                border: 1px solid transparent;
                border-bottom: 2px solid #000;
                font-weight: 700;
            }

            .nav-link {
                color: #000;

                &:hover {
                    border: 1px solid transparent;
                    border-bottom: 2px solid #000;
                }
            }
        }
    }

    .blog-tab-content {
        .tab-pane {
            .card {
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                a {
                    text-decoration: none;
                    color: #000;

                    .card-body {
                        text-align: left;
                        border-top: 8px solid #F0AD00;
                    }
                }
            }
        }
    }

}