@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {
    .life-at-eapro-slider-section {
        .heading-tabs-parent {
            .slider-parent-dv {
                .slider-item-dv {

                    .slider-item-img {
                        margin-bottom: 10px;
                        position: relative;
                        text-align: center;

                        img {
                            margin: 0 auto;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .slick-next {
            background: url('../../../../../public/images/small-right.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            right: -20px;

            &::before {
                display: none;
            }
        }

        .slick-slider {
            .slick-slide {
                // margin: 30px 0px;
                // padding: 20px 15px;
                transition: all 0.5s ease-in-out;
            }
        }

        .slick-prev {
            background: url('../../../../../public/images/small-left.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            left: -20px;

            &::before {
                display: none;
            }
        }
    }
}

@media only screen and (min-width:321px) and (max-width:767px) {
    .life-at-eapro-slider-section {
        .heading-tabs-parent {
            .slider-parent-dv {
                .slider-item-dv {

                    .slider-item-img {
                        margin-bottom: 10px;
                        position: relative;
                        text-align: center;

                        img {
                            margin: 0 auto;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .slick-next {
            background: url('../../../../../public/images/small-right.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            right: 0px;

            &::before {
                display: none;
            }
        }

        .slick-slider {
            .slick-slide {
                // margin: 30px 0px;
                // padding: 20px 15px;
                transition: all 0.5s ease-in-out;
            }
        }

        .slick-prev {
            background: url('../../../../../public/images/small-left.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            left: 0px;
            z-index: 99;

            &::before {
                display: none;
            }
        }
    }
}

@media only screen and (min-width:100px) and (max-width:320px) {
    .life-at-eapro-slider-section {
        .heading-tabs-parent {
            .slider-parent-dv {
                .slider-item-dv {

                    .slider-item-img {
                        margin-bottom: 10px;
                        position: relative;
                        text-align: center;

                        img {
                            margin: 0 auto;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .slick-next {
            background: url('../../../../../public/images/small-right.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            right: -20px;

            &::before {
                display: none;
            }
        }

        .slick-slider {
            .slick-slide {
                // margin: 30px 0px;
                // padding: 20px 15px;
                transition: all 0.5s ease-in-out;
            }
        }

        .slick-prev {
            background: url('../../../../../public/images/small-left.png')center center no-repeat !important;
            width: 20px;
            height: 100%;
            left: -20px;

            &::before {
                display: none;
            }
        }
    }
}