// <!------- product-1  ------------>
.power-product-custom-product-1 {
  position: relative;
  padding: 50px 0px;
  overflow: hidden;
  text-align: left;

  .bg-graphic-right-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(25%, -10%);

    img {
      width: 80%
    }
  }

  .bg-graphic-left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translate(0, 10%);

    img {
      width: 80%;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 20px;
    text-shadow: 1px 2px #b7aeae;
  }

  .product-img {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
    height: 100%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // margin-top: auto;
      // margin-bottom: auto;
    }
  }

  .product-info {
    h2 {
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .view-all-btn {
      margin-top: 50px;
      color: #fdbf05;
      background-color: #000;
      border: none;
      border-radius: 10px;
      text-transform: capitalize;
      padding: 5px 10px;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

// <!------- product-2  ------------>
.power-product-custom-product-2 {
  position: relative;
  padding: 50px 0px;
  background-color: #F1F0F0;
  overflow: hidden;
  z-index: 10;
  text-align: left;

  .bg-graphic-center {
    position: absolute;
    top: 50%;
    transform: translate(0, -40%);
    z-index: -1;

    img {
      // width:80%;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .product-img {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
    height: 100%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // margin-top: auto;
      // margin-bottom: auto;
    }
  }

  .product-info {
    h2 {
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .view-all-btn {
      margin-top: 50px;
      color: #000;
      background-color: #fdbf05;
      border: none;
      border-radius: 10px;
      text-transform: capitalize;
      padding: 5px 10px;
      font-weight: 500;
      text-decoration: none;
    }
  }


}

// <!------- product-3  ------------>
.power-product-custom-product-3 {
  position: relative;
  padding: 50px 0px;
  overflow: hidden;
  text-align: left;

  .bg-graphic-right-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(25%, -10%);

    img {
      width: 80%
    }
  }

  .bg-graphic-left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translate(0, 10%);

    img {
      width: 80%;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .product-img {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
    height: 100%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // margin-top: auto;
      // margin-bottom: auto;
    }
  }

  .product-info {
    h2 {
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .view-all-btn {
      margin-top: 50px;
      color: #000;
      background-color: #fdbf05;
      border: none;
      border-radius: 10px;
      text-transform: capitalize;
      padding: 5px 10px;
      font-weight: 500;
      text-decoration: none;
    }
  }
}