.installation-help-page-content-section {
    background: #000;
    background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px 0px 0px;
    background-attachment: fixed;

    .modal-dialog {
        box-shadow: 0px 0px 5.5px 5px #00000040;
        overflow: hidden;
    }

    /* Checkout Css */
    .order-place h3 {
        color: #08AAE3;
    }

    .order-image img {
        height: 320px;
    }

    .thank-you button {
        border: none;
        padding: 8px 16px;
        border-radius: 10px;
        color: #fff;
        background: linear-gradient(92.83deg, #08AAE3 -74.66%, #FF9811 343.14%);
    }

    .modal {
        margin-top: 100px;
    }

    .modal-dialog {
        box-shadow: 0px 0px 5.5px 5px #00000040;
        overflow: hidden;
    }

    .help-support-heading {
        h5 {
            font-weight: 700;
        }
    }

    .help-support-content-dv {
        margin: 20px 0px;
    }

    .form-parent-custon-dv {
        text-align: left;
        border-radius: 16px;
        background: #F1F0F0;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
        margin-bottom: 50px;

        .custom-row-form {
            text-align: left;

            .label-background {
                background: #4444441a;

            }

            input {
                &::placeholder {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            select {
                font-size: 13px;
                color: #818181;
                font-style: italic;
            }

            .upload-product-front {
                border: 1px solid #ccc;
                padding: 5px;
                width: 100%;
                border-radius: 5px;
                background-color: #fff;
                position: relative;

                .upload-product-icon {
                    position: absolute;
                    right: 6px;
                    top: 4px;

                    img {
                        width: 25px;
                    }
                }

                span {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            .upload-product-back {
                border: 1px solid #ccc;
                padding: 5px;
                width: 100%;
                border-radius: 5px;
                background-color: #fff;
                position: relative;

                .upload-product-icon {
                    position: absolute;
                    right: 6px;
                    top: 4px;

                    img {
                        width: 25px;
                    }
                }

                span {
                    font-size: 13px;
                    color: #818181;
                    font-style: italic;
                }
            }

            .submit-btn-custom {
                background-color: #000;
                color: #FFBC00;
            }

            .react-international-phone-input {
                display: block;
                width: 100%;
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: var(--bs-body-color);
                appearance: none;
                background-color: var(--bs-body-bg);
                background-clip: padding-box;
                border: var(--bs-border-width) solid var(--bs-border-color);
                border-radius: var(--bs-border-radius);
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

                border: none;

            }

            .react-international-phone-country-selector-button {
                border: none;
            }


        }
    }

    .help-and-support-section-inner {
        background: #cccccc5c;
        background-image: url("../../../../public/images/help-support-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // background-color: #F1F0F0;
        padding: 50px 0px;
        background-color: transparent;

        .heading-dv {
            h5 {
                font-weight: 700;
                color: #000;
                font-size: 22px;
            }
        }

        .help-support-inner-section {
            .first-card-parent {
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 10px 20px;
                margin: 20px 0px;

                .mobile-tag {
                    text-align: left;

                    p {
                        margin-bottom: 0px;
                        margin: 10px 0px;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-weight: 700;
                        }

                        img {
                            width: 24px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .second-side-card-parent {
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 10px 20px;
                margin: 20px 0px;

                .mobile-tag {
                    text-align: left;

                    p {
                        margin-bottom: 0px;
                        margin: 10px 0px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: space-between;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-weight: 700;
                        }

                        img {
                            width: 24px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

}

.help-and-support-section-inner {
    // background: #cccccc5c;
    // background-image: url("../../../../public/images/help-support-bg.png");
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    padding: 50px 0px;
    background-color: transparent;

    .heading-dv {
        h5 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .help-support-inner-section {
        .first-card-parent {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px 20px;
            margin: 20px 0px;

            .mobile-tag {
                text-align: left;

                p {
                    margin-bottom: 0px;
                    margin: 10px 0px;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .second-side-card-parent {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px 20px;
            margin: 20px 0px;

            .mobile-tag {
                text-align: left;

                p {
                    margin-bottom: 0px;
                    margin: 10px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: space-between;
                    align-items: center;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }





}