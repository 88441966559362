.group-of-eapro-companies-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/why-bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.group-of-eapro-companies-section .heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.group-of-eapro-companies-section .group-card-parent {
  border-radius: 10px;
  margin: 80px 0px 40px 0px;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip {
  position: relative;
  padding: 25px;
  height: 220px;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip .group-icon-dv {
  position: absolute;
  left: 0px;
  top: -70px;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip .group-icon-dv img {
  width: 135px;
  height: 100%;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip .group-text-heading {
  background-color: #fff;
  padding: 25px 10px;
  border-radius: 10px;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip .group-text-heading h6 {
  margin-bottom: 0;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip .group-text-heading h6 a {
  text-decoration: none;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #000;
  margin-left: 50px;
  font-size: 13px;
}
.group-of-eapro-companies-section .group-card-parent .group-card-icon-strip .group-content-para p {
  margin: 10px 0px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}
.group-of-eapro-companies-section .group-card-parent-yellow {
  background: rgb(255, 230, 1);
  background: radial-gradient(circle, rgb(255, 230, 1) 0%, rgb(249, 178, 21) 61%);
}
.group-of-eapro-companies-section .group-card-parent-dark {
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 61%);
}/*# sourceMappingURL=GroupOfEaproCompanies.css.map */