@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .quality-assurance-content-sec .new-page-heading h5 {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .quality-assurance-banner-sec .business-heading-top h3 {
    font-weight: 700;
    font-size: 60px;
  }
  .quality-assurance-banner-sec .business-heading h3 {
    font-size: 30px;
  }
  .quality-assurance-banner-sec .business-heading {
    position: relative;
    text-align: center;
  }
  .quality-assurance-banner-sec .business-heading img {
    height: 100px;
    width: 100%;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading {
    position: absolute;
    bottom: 15%;
    left: 0%;
    transform: translate(0%, -15%);
    width: 100%;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading h3 {
    font-weight: 700;
    font-size: 20px;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 20px;
    width: 100%;
  }
  .quality-assurance-banner-sec .banner-top-img-bg-rel {
    position: relative;
  }
  .quality-assurance-banner-sec .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    bottom: 15%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .quality-assurance-content-sec .new-page-heading h5 {
    width: 68%;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .quality-assurance-banner-sec .business-heading-top h3 {
    font-weight: 700;
    font-size: 40px;
  }
  .quality-assurance-banner-sec .business-heading h3 {
    font-size: 32px;
  }
  .quality-assurance-banner-sec .business-heading {
    position: relative;
    text-align: center;
  }
  .quality-assurance-banner-sec .business-heading img {
    height: 100%;
    width: 100%;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading h3 {
    font-weight: 700;
    font-size: 18px;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 16px;
    width: 100%;
  }
  .quality-assurance-banner-sec .banner-top-img-bg-rel {
    position: relative;
  }
  .quality-assurance-banner-sec .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .quality-assurance-content-sec .new-page-heading h5 {
    font-size: small;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .quality-assurance-banner-sec .business-heading-top h3 {
    font-weight: 700;
    font-size: 40px;
  }
  .quality-assurance-banner-sec .business-heading h3 {
    font-size: 18px;
  }
  .quality-assurance-banner-sec .business-heading {
    position: relative;
    text-align: center;
  }
  .quality-assurance-banner-sec .business-heading img {
    height: 100%;
    width: 100%;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading h3 {
    font-weight: 700;
    font-size: 14px;
  }
  .quality-assurance-banner-sec .business-heading .business-banner-heading h5 {
    font-weight: 700;
    font-size: 14px;
    width: 100%;
  }
  .quality-assurance-banner-sec .banner-top-img-bg-rel {
    position: relative;
  }
  .quality-assurance-banner-sec .banner-top-img-bg-rel .banner-top-content-absol {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
  .quality-assurance-content-sec .new-page-heading h5 {
    font-size: small;
    width: -moz-fit-content;
    width: fit-content;
  }
}/*# sourceMappingURL=ResponsiveQualityAssurance.css.map */