.career-at-eapro-two-con-search-jobs {
  background-color: #F1F0F0;
  padding: 40px;
  margin-top: 40px;
}
.career-at-eapro-two-con-search-jobs .search-job-btn {
  margin: 30px 0px 10px 0px;
}
.career-at-eapro-two-con-search-jobs .search-job-btn a {
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.career-at-eapro-two-page-content-section {
  background: #000;
  background-image: url("../../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.career-at-eapro-two-page-content-section .career-content-heading h5 {
  font-weight: 700;
}
.career-at-eapro-two-page-content-section .form-parent-custon-dv {
  background-color: #F1F0F0;
  padding: 40px;
  border-radius: 5px;
}
.career-at-eapro-two-page-content-section .form-parent-custon-dv .card-parent-dv h4 {
  font-weight: 700;
}
.career-at-eapro-two-page-content-section .form-parent-custon-dv .card-parent-dv .job-description-dv ul {
  list-style-type: disc;
}
.career-at-eapro-two-page-content-section .form-parent-custon-dv .card-parent-dv .job-apply-btn {
  text-align: center;
  margin-top: 20px;
}
.career-at-eapro-two-page-content-section .form-parent-custon-dv .card-parent-dv .job-apply-btn a {
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
}/*# sourceMappingURL=CareerAtEaproTwoContent.css.map */