@media only screen and (max-width:1201px) {}

@media only screen and (min-width:993px) and (max-width:1200px) {}

@media only screen and (min-width:768px) and (max-width:992px) {}

@media only screen and (min-width:321px) and (max-width:767px) {
    .csr-page-content-section {
        .blog-nav-tabs {
            border-bottom: 1px solid transparent;
            justify-content: center;
            padding: 50px 0px;
            flex-wrap: nowrap;

            .nav-item {
                .nav-link {
                    font-size: 14px;
                }

                .nav-link.active {
                    font-size: 14px;
                    font-weight: 700;
                    border-bottom: 1px solid #000;
                }
            }
        }
    }
}

@media only screen and (min-width:100px) and (max-width:320px) {
    .csr-page-content-section {
        .blog-nav-tabs {
            border-bottom: 1px solid transparent;
            justify-content: center;
            padding: 50px 0px;
            flex-wrap: wrap;
        }
    }
}