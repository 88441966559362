@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .know-more-about-power-section {
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .know-more-about-power-section .slick-next {
    background: url("../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -10px;
  }
  .know-more-about-power-section .slick-next::before {
    display: none;
  }
  .know-more-about-power-section .slick-slider .slick-slide {
    margin: 30px 0px;
    padding: 10px 50px;
    transition: all 0.5s ease-in-out;
  }
  .know-more-about-power-section .slick-dots {
    bottom: -55px;
  }
  .know-more-about-power-section .slick-prev {
    background: url("../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -10px;
  }
  .know-more-about-power-section .slick-prev::before {
    display: none;
  }
  .know-more-about-power-section .slider-parent-dv {
    padding: 0px 0px;
    height: 100%;
  }
  .know-more-about-power-section .slider-parent-dv .slider-item-dv .slider-item-heading h4 {
    font-size: 12px;
    font-weight: 700;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .know-more-about-power-section .slick-next {
    background: url("../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -10px;
  }
  .know-more-about-power-section .slick-next::before {
    display: none;
  }
  .know-more-about-power-section .slick-prev {
    background: url("../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -10px;
  }
  .know-more-about-power-section .slick-prev::before {
    display: none;
  }
  .know-more-about-power-section .slider-parent-dv {
    padding: 0px 0px;
    height: 300px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .know-more-about-power-section .slick-next {
    background: url("../../../../public/images/small-right.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    right: -10px;
  }
  .know-more-about-power-section .slick-next::before {
    display: none;
  }
  .know-more-about-power-section .slick-prev {
    background: url("../../../../public/images/small-left.png") center center no-repeat !important;
    width: 20px;
    height: 100%;
    left: -10px;
  }
  .know-more-about-power-section .slick-prev::before {
    display: none;
  }
  .know-more-about-power-section .slider-parent-dv {
    padding: 0px 0px;
    height: 245px;
  }
}/*# sourceMappingURL=ResponsiveKnowMoreAboutPower.css.map */