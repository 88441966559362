.power-solution-converter-and-controller-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/our-speciali-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.power-solution-converter-and-controller-section .specialization-heading-dv {
  margin: 30px 0px;
}
.power-solution-converter-and-controller-section .specialization-heading-dv h5 {
  font-weight: 700;
  font-size: 22px;
}
.power-solution-converter-and-controller-section .right-content-parent {
  text-align: left;
}
.power-solution-converter-and-controller-section .right-content-parent .img-content-dv {
  display: flex;
  align-items: center;
}
.power-solution-converter-and-controller-section .right-content-parent .img-content-dv p {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 0px;
}
.power-solution-converter-and-controller-section .right-content-parent .view-product-learn-more {
  margin-top: 40px;
}
.power-solution-converter-and-controller-section .right-content-parent .view-product-learn-more .view-product-btn {
  color: #FFBC00;
  background-color: #000;
  text-decoration: none;
  padding: 7px 20px;
  margin: 0px 20px;
  border-radius: 5px;
}
.power-solution-converter-and-controller-section .right-content-parent .view-product-learn-more .learn-more-btn {
  padding: 10px 20px;
}/*# sourceMappingURL=ConverterAndControllerPage.css.map */