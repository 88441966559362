.product-details-page-banner-section {

  .slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .screen-sixteen-nine-four-sec {
    position: relative;

    .screen-sixteen-nine-four-img-sec {
      position: fixed;
      right: 0;
      z-index: -1;

      img {
        height: 400px;
        width: 500px;
      }
    }

    //!-- Start Screen-16:9-4 Section-1 -->
    .screen-sixteen-nine-four-section-one {
      .section-one-parent-one {
        height: 450px;

        .card {
          // margin-left: 100px;
          // margin-right: 20px;
          // height: 300px;
          margin: 9% 0;
          background-color: #f2f2f0;
          border-radius: 10px;
          border: none;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

          .card-body {
            .img-sec {
              padding: 0 20%;

              img {
                height: 250px;
              }
            }

            .slider-sec {
              position: relative;

              //   .slick-prev:before,
              .slick-next:before {
                color: #000;
                content: "\f054";
                font-family: FontAwesome;
              }

              .slick-prev:before {
                color: #000;
                content: "\f053";
                font-family: FontAwesome;
              }

              .slick-slide {
                scale: 0.8;
              }

              .slick-current {
                scale: 0.9;
              }

              .card {
                border: 1px solid #c1c1c1;
                background-color: #fff;
                box-shadow: none;
                margin-right: 5px;

                .card-body {
                  .img-sec {
                    padding: 5%;
                    height: 70px;
                    width: 80px;

                    img {
                      height: 100%;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .section-one-parent-two {
        height: 450px;
        position: relative;

        .img-sec {
          position: fixed;
          right: 0;
          padding-left: 180px;
        }

        .section-one-parent-two-child {
          .section-one-parent-two-sub-child {
            .heading-sec {
              h1 {
                font-size: 32px;
              }

              p {
                font-weight: 400;
              }
            }

            .scroll-height-fix-dv {
              height: 284px;
              overflow-y: scroll;
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: unset;
                background-color: transparent;
                border: 1px solid transparent;
              }
              &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #000000;
              }
            }

            .para-sec {
              padding-right: 30%;

              p {
                font-size: 14px;
              }
            }

            .keyfeatures-sec {
              p {
                font-weight: 400;
                font-size: 15px;
              }

              ul li {
                font-size: 14px;
              }
            }

            .btns {
              display: flex;

              .btn-one {
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                border: none;
                background-color: #fcc300;
                font-size: 14px;
                font-weight: 500;
                padding: 1% 2%;
                margin-right: 15px;
                width: max-content;

                img {
                  height: 25px;
                }
              }

              .btn-one:hover {
                background-color: #d9d9d9;
              }

              .btn-two {
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                border: none;
                background-color: #d9d9d9;
                font-size: 14px;
                font-weight: 500;
                padding: 1% 2%;
                margin-right: 15px;
                width: max-content;

                img {
                  height: 25px;
                }
              }

              .btn-two:hover {
                background-color: #fdbf05;
              }

              .btn-three {
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                border: none;
                background-color: #fdbf05;
                font-size: 14px;
                font-weight: 500;
                padding: 1% 2%;

                .btn-three-img-sec {
                  position: relative;

                  img {
                    height: 25px;
                  }

                  .font-icon {
                    position: absolute;
                    top: 20%;
                    left: 20%;
                    font-size: 80%;
                  }
                }
              }

              .btn-three:hover {
                background-color: #d9d9d9;
              }
            }
          }
        }
      }
    }

    //!-- End Screen-16:9-4 Section-1 -->

    //!-- Start Screen-16:9-4 Section-2 -->
    .screen-sixteen-nine-four-section-two {
      .section-one-parent-three {
        position: relative;

        .nav.nav-tabs {
          justify-content: center;
          align-items: center;
          display: flex;
          border: none;

          .nav-item {
            .nav-link.active {
              background: transparent;
              color: #000;
              font-weight: 700;
              border: none;
              border-bottom: 1px solid !important;
            }

            .nav-link {
              color: #000;
              border: none;
            }
          }
        }

        .tab-content {
          .tab-pane {
            table {
              border: 1px solid #bbbbb7;
              // width: 100%;
              margin-top: 30px;

              .t-heading {
                th {
                  padding-top: 40px;
                  // width: 100%;
                }
              }

              tr {
                border-bottom: 1px solid #bbbbb7;
              }
            }

            .matter-list {
              .group-lists {
                margin-top: 40px;
                border: 1px solid rgba(165, 164, 164, 0.308);
                background-color: #fff;

                .lists-blank {
                  border: 1px solid rgba(190, 190, 190, 0.322);
                  padding: 5px 5px 10px 35px;

                  .blank {
                    visibility: hidden;
                  }
                }

                .lists {
                  border: 1px solid rgba(190, 190, 190, 0.322);
                  padding: 5px 5px 10px 35px;
                  text-align: start;
                }
              }
            }
          }
        }

        .screen-sixteen-nine-four-section-two-img-sec {
          position: fixed;
          z-index: -1;
          bottom: 0;
          left: 0;

          img {
            width: 500px;
            height: 400px;
          }
        }
      }
    }

    //!-- End Screen-16:9-4 Section-2 -->

    //!-- Start Screen-16:9-4 Section-3 -->
    .screen-sixteen-nine-four-section-three {
      background-color: #f2f2f0;
      padding: 50px 0;

      .section-one-parent-four {
        .heading {
          justify-content: center;
          align-items: center;
          display: flex;

          h5 {
            font-weight: 700;
          }
        }

        .section-one-parent-four-content-box-one {
          background: #fff;
          height: 100%;
          padding: 40px 20px;
          padding-top: 0.5rem;
          border: 1px solid;
          text-align: center;
          border: none;
          border-radius: 8px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

          .icon-sec {
            text-align: center;

            i {
              font-size: 20px;
              margin-right: 10px;
              color: #e3e3e2;
            }

            .rated {
              color: #fdbf05;
            }

            .unrated {
              color: #8c8c89;
            }
          }

          h1 {
            font-weight: 700;
          }

          h6 {
            font-weight: 700;
            margin-bottom: 30px;
          }
        }

        .section-one-parent-four-content-box-two {
          height: 100%;
          background: #fff;
          border: 1px solid;
          border: none;
          border-radius: 8px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

          h6 {
            margin-bottom: 20px;
            text-align: left;
            font-weight: 700;
          }

          .progress-one,
          .progress-two,
          .progress-three,
          .progress-four,
          .progress-five {
            display: flex;

            .number-five,
            .percent {
              font-size: 17px;
              width: 30px;
              color: #8c8c89;
            }

            .star-icon {
              font-size: 16px;
              width: 50px;
              color: #fdbf05;
            }

            .progress {
              height: 15px;
              width: 85%;
              margin-top: 0.5%;

              .progress-bar {
                height: 20px;
              }
            }

            .percent {
              padding-left: 20px;
              font-size: 16px;
              width: 50px;
              color: #8c8c89;
            }
          }
        }

        .write-review-btn {
          margin-top: 40px;
          color: #000;
          background-color: #fcc300;
          border: none;
          padding: 10px;
        }
      }
    }

    //!-- End Screen-16:9-4 Section-3 -->

    //!-- Start Screen-16:9-4 Section-4 -->
    .screen-sixteen-nine-four-section-four {
      background-color: #f2f2f0;

      .section-two-parent-one {
        .heading-sec {
          text-align: center;

          h5 {
            font-weight: 700;
          }

          p {
            font-weight: 600;
          }
        }

        .section-two-child-sec {
          .section-two-child-one {
            text-align: center;

            .star-icon i {
              font-size: 30px;
              margin-right: 10px;
              color: #e3e3e2;

              &.rated {
                color: #fdbf05;
              }
            }

            .form-sec {

              .input-sec,
              .textarea-sec {
                input {
                  width: 80%;
                  border-radius: 5px;
                  padding: 5px 0;
                  border: 1px solid #c0c0c0;
                }

                textarea {
                  width: 80%;
                  height: 130px;
                  border-radius: 5px;
                  border: 1px solid #c0c0c0;
                }
              }

              .submit-review-btn {
                margin: 0 0 40px 0;
                color: #000;
                background-color: #fcc300;
                border: none;
                padding: 10px;
              }
            }

            .section-two-sub-child {
              //   padding: 0 10%;

              .section-two-sub-child-parent-one {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

                .section-two-sub-child-one,
                .section-two-sub-child-two,
                .section-two-sub-child-three {
                  border: none;
                  height: 100%;
                  //   height: 180px;
                }

                .section-two-sub-child-one {
                  border-radius: 10px 0 0 10px;
                  background-color: #d9d9d9;
                  padding: 50px 20px;
                }

                .section-two-sub-child-two {
                  background-color: white;
                  padding: 50px 0;

                  h5 {
                    color: #fdbf05;
                    font-weight: 700;
                  }

                  .para-one {
                    color: #8c8c89;
                  }

                  .para-two {
                    color: #8c8c89;
                  }
                }

                .section-two-sub-child-three {
                  background-color: white;
                  border-radius: 0px 10px 10px 0px;
                  padding: 20px 0;

                  .star-icon {
                    margin-bottom: 10px;
                    text-align: start;

                    i {
                      font-size: 20px;
                    }
                  }

                  .heading-sec {
                    text-align: start;

                    h6 {
                      font-weight: 700;
                    }
                  }

                  .para {
                    text-align: start;
                    padding: 0 10px 0 0;

                    p {
                      font-size: 100%;
                      color: #8c8c89;
                    }
                  }
                }
              }
            }

            .show-more-btn {
              justify-content: center;
              align-items: center;
              display: flex;

              .btn {
                background: #fdbf05;
                font-weight: 700;

                a {
                  color: #000;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }

    //!-- End Screen-16:9-4 Section-4 -->
  }
}

// ===================================================================

//**** ----------------------- Start Laptop ------------------------- ****//
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .product-details-page-banner-section {
    .screen-sixteen-nine-four-sec {
      position: relative;

      .screen-sixteen-nine-four-img-sec {
        position: fixed;
        right: 0;

        img {
          height: 400px;
          width: 500px;
        }
      }

      //!-- Start Screen-16:9-4 Section-1 -->
      .screen-sixteen-nine-four-section-one {
        .section-one-parent-one {
          height: 450px;

          .card {
            // margin-left: 100px;
            // margin-right: 20px;
            // height: 300px;
            margin: 9% 0;
            background-color: #f2f2f0;
            border-radius: 10px;
            border: none;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .card-body {
              .img-sec {
                padding: 0 20%;

                img {
                  height: 250px;
                }
              }

              .slider-sec {
                position: relative;

                .card {
                  border: 1px solid #c1c1c1;
                  background-color: #fff;
                  box-shadow: none;
                  margin-right: 5px;

                  .card-body {
                    .img-sec {
                      padding: 4%;
                      height: 60px;
                      width: 60px;

                      img {
                        height: 100%;
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .section-one-parent-two {
          height: 450px;
          position: relative;

          .img-sec {
            position: absolute;
            right: 0;
            padding-left: 180px;
          }

          .section-one-parent-two-child {
            .section-one-parent-two-sub-child {
              .heading-sec {
                h1 {
                  font-size: 32px;
                }

                p {
                  font-weight: 400;
                }
              }

              .para-sec {
                padding-right: 30%;

                p {
                  font-size: 14px;
                }
              }

              .keyfeatures-sec {
                p {
                  font-weight: 400;
                  font-size: 15px;
                }

                ul li {
                  font-size: 14px;
                }
              }

              .btns {
                display: flex;

                .btn-one {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                  border-radius: 8px;
                  border: none;
                  background-color: #fcc300;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 1% 1%;
                  margin-right: 8px;

                  img {
                    height: 25px;
                  }
                }

                .btn-one:hover {
                  background-color: #d9d9d9;
                }

                .btn-two {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                  border-radius: 8px;
                  border: none;
                  background-color: #d9d9d9;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 1% 1%;
                  margin-right: 8px;

                  img {
                    height: 25px;
                  }
                }

                .btn-two:hover {
                  background-color: #fdbf05;
                }

                .btn-three {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                  border-radius: 8px;
                  border: none;
                  background-color: #fdbf05;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 1% 1%;

                  .btn-three-img-sec {
                    position: relative;

                    img {
                      height: 25px;
                    }

                    .font-icon {
                      position: absolute;
                      top: 20%;
                      left: 20%;
                      font-size: 80%;
                    }
                  }
                }

                .btn-three:hover {
                  background-color: #d9d9d9;
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-1 -->

      //!-- Start Screen-16:9-4 Section-2 -->
      .screen-sixteen-nine-four-section-two {
        .section-one-parent-three {
          position: relative;

          .nav.nav-tabs {
            justify-content: center;
            align-items: center;
            display: flex;
            border: none;

            .nav-item {
              .nav-link.active {
                color: #000;
                font-weight: 700;
                border: none;
                border-bottom: 1px solid !important;
              }

              .nav-link {
                color: #000;
                border: none;
              }
            }
          }

          .tab-content {
            .tab-pane {
              table {
                border: 1px solid #bbbbb7;
                // width: 100%;
                margin-top: 30px;

                .t-heading {
                  th {
                    padding-top: 30px;
                    // width: 100%;
                  }
                }

                tr {
                  border-bottom: 1px solid #bbbbb7;
                }
              }
            }
          }

          .screen-sixteen-nine-four-section-two-img-sec {
            position: fixed;
            bottom: 0;
            left: 0;

            img {
              width: 500px;
              height: 400px;
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-2 -->

      //!-- Start Screen-16:9-4 Section-3 -->
      .screen-sixteen-nine-four-section-three {
        .section-one-parent-four {
          .heading {
            justify-content: center;
            align-items: center;
            display: flex;

            h5 {
              font-weight: 700;
            }
          }

          .section-one-parent-four-content-box-one {
            padding: 40px 20px;
            border: 1px solid;
            text-align: center;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
          }

          .section-one-parent-four-content-box-two {
            border: 1px solid;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .progress-one,
            .progress-two,
            .progress-three,
            .progress-four,
            .progress-five {
              display: flex;

              .number-five,
              .percent {
                font-size: 17px;
                width: 30px;
                color: #8c8c89;
              }

              .star-icon {
                font-size: 16px;
                width: 50px;
                color: #fdbf05;
              }

              .progress {
                height: 16px;
                width: 600px;
                margin-top: 0.5%;

                .progress-bar {
                  height: 20px;
                }
              }

              .percent {
                padding-left: 20px;
                font-size: 16px;
                width: 50px;
                color: #8c8c89;
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-3 -->

      //!-- Start Screen-16:9-4 Section-4 -->
      .screen-sixteen-nine-four-section-four {
        .section-two-parent-one {
          .heading-sec {
            text-align: center;

            h5 {
              font-weight: 700;
            }

            p {
              font-weight: 600;
            }
          }

          .section-two-child-sec {
            .section-two-child-one {
              background-color: #f2f2f0;
              text-align: center;

              .form-sec {

                .input-sec,
                .textarea-sec {
                  input {
                    width: 80%;
                    border-radius: 5px;
                    padding: 5px 0;
                    border: 1px solid #c0c0c0;
                  }

                  textarea {
                    width: 80%;
                    height: 130px;
                    border-radius: 5px;
                    border: 1px solid #c0c0c0;
                  }
                }
              }

              .section-two-sub-child {
                // padding: 0 10%;

                .section-two-sub-child-parent-one {
                  width: 100%;
                  border-radius: 10px;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

                  .section-two-sub-child-one,
                  .section-two-sub-child-two,
                  .section-two-sub-child-three {
                    border: none;
                    height: 100%;
                  }

                  .section-two-sub-child-one {
                    border-radius: 10px 0 0 10px;
                    background-color: #d9d9d9;
                    padding: 50px 20px;
                  }

                  .section-two-sub-child-two {
                    background-color: white;
                    padding: 50px 0;

                    h5 {
                      color: #fdbf05;
                      font-weight: 700;
                    }

                    .para-one {
                      color: #8c8c89;
                    }

                    .para-two {
                      color: #8c8c89;
                    }
                  }

                  .section-two-sub-child-three {
                    background-color: white;
                    border-radius: 0px 10px 10px 0px;
                    padding: 20px 0;

                    .star-icon {
                      margin-bottom: 10px;
                      text-align: start;

                      i {
                        font-size: 20px;
                      }
                    }

                    .heading-sec {
                      text-align: start;

                      h6 {
                        font-weight: 700;
                      }
                    }

                    .para {
                      text-align: start;
                      padding: 0 10px 0 10px;

                      p {
                        font-size: 90%;
                        color: #8c8c89;
                      }
                    }
                  }
                }
              }

              .show-more-btn {
                justify-content: center;
                align-items: center;
                display: flex;

                .btn {
                  background: #fdbf05;
                  font-weight: 700;

                  a {
                    color: #000;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-4 -->
    }
  }
}

//**** ----------------------- End Laptop ------------------------- ****//

//**** ----------------------- Start Tablet ------------------------- ****//
@media only screen and (min-width: 568px) and (max-width: 992px) {
  .product-details-page-banner-section {
    .screen-sixteen-nine-four-sec {
      position: relative;

      .screen-sixteen-nine-four-img-sec {
        position: fixed;
        right: 0;

        img {
          height: 400px;
          width: 500px;
        }
      }

      //!-- Start Screen-16:9-4 Section-1 -->
      .screen-sixteen-nine-four-section-one {
        .section-one-parent-one {
          height: 400px;

          .card {
            // margin-left: 100px;
            // margin-right: 20px;
            // height: 300px;
            margin: 9% 0;
            background-color: #f2f2f0;
            border-radius: 10px;
            border: none;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .card-body {
              .img-sec {
                padding: 0 20%;

                img {
                  height: 250px;
                }
              }

              .slider-sec {
                position: relative;

                .card {
                  border: 1px solid #c1c1c1;
                  background-color: #fff;
                  box-shadow: none;
                  margin-right: 5px;

                  .card-body {
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .img-sec {
                      img {
                        height: 80px;
                        width: 80px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .section-one-parent-two {
          position: relative;

          .img-sec {
            position: absolute;
            right: 0;
            padding-left: 180px;
          }

          .section-one-parent-two-child {
            .section-one-parent-two-sub-child {
              .heading-sec {
                h1 {
                  font-size: 32px;
                }

                p {
                  font-weight: 400;
                }
              }

              .para-sec {
                padding-right: 30%;

                p {
                  font-size: 14px;
                }
              }

              .keyfeatures-sec {
                p {
                  font-weight: 400;
                  font-size: 15px;
                }

                ul li {
                  font-size: 14px;
                }
              }

              .btns {
                .btn-one {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                  border-radius: 8px;
                  border: none;
                  background-color: #fcc300;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 1% 5%;
                  margin-right: 10px;

                  img {
                    height: 25px;
                  }
                }

                .btn-two {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                  border-radius: 8px;
                  border: none;
                  background-color: #d9d9d9;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 1% 5%;

                  img {
                    height: 25px;
                  }
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-1 -->

      //!-- Start Screen-16:9-4 Section-2 -->
      .screen-sixteen-nine-four-section-two {
        .section-one-parent-three {
          position: relative;

          .nav.nav-tabs {
            justify-content: center;
            align-items: center;
            display: flex;
            border: none;

            .nav-item {
              .nav-link.active {
                color: #000;
                font-weight: 700;
                border: none;
                border-bottom: 1px solid !important;
              }

              .nav-link {
                color: #000;
                border: none;
              }
            }
          }

          .tab-content {
            .tab-pane {
              table {
                border: 1px solid #bbbbb7;
                // width: 100%;
                margin-top: 30px;

                .t-heading {
                  th {
                    padding-top: 30px;
                    // width: 100%;
                  }
                }

                tr {
                  border-bottom: 1px solid #bbbbb7;
                }
              }
            }
          }

          .screen-sixteen-nine-four-section-two-img-sec {
            position: fixed;
            bottom: 0;
            left: 0;

            img {
              width: 500px;
              height: 400px;
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-2 -->

      //!-- Start Screen-16:9-4 Section-3 -->
      .screen-sixteen-nine-four-section-three {
        .section-one-parent-four {
          .heading {
            justify-content: center;
            align-items: center;
            display: flex;

            h5 {
              font-weight: 700;
            }
          }

          .section-one-parent-four-content-box-one {
            padding: 40px 20px;
            border: 1px solid;
            text-align: center;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .icon-sec {
              i {
                margin-right: 0;
              }
            }
          }

          .section-one-parent-four-content-box-two {
            border: 1px solid;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .progress-one,
            .progress-two,
            .progress-three,
            .progress-four,
            .progress-five {
              display: flex;

              .number-five,
              .percent {
                font-size: 17px;
                width: 30px;
                color: #8c8c89;
              }

              .star-icon {
                font-size: 16px;
                width: 50px;
                color: #fdbf05;
              }

              .progress {
                height: 15px;
                width: 600px;
                margin-top: 1%;

                .progress-bar {
                  height: 20px;
                }
              }

              .percent {
                padding-left: 20px;
                font-size: 16px;
                width: 50px;
                color: #8c8c89;
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-3 -->

      //!-- Start Screen-16:9-4 Section-4 -->
      .screen-sixteen-nine-four-section-four {
        .section-two-parent-one {
          .heading-sec {
            text-align: center;

            h5 {
              font-weight: 700;
            }

            p {
              font-weight: 600;
            }
          }

          .section-two-child-sec {
            .section-two-child-one {
              background-color: #f2f2f0;
              text-align: center;

              .star-icon i {
                font-size: 30px;
                color: #fdbf05;
              }

              .form-sec {

                .input-sec,
                .textarea-sec {
                  input {
                    width: 80%;
                    border-radius: 5px;
                    padding: 5px 0;
                    border: 1px solid #c0c0c0;
                  }

                  textarea {
                    width: 80%;
                    height: 130px;
                    border-radius: 5px;
                    border: 1px solid #c0c0c0;
                  }
                }
              }

              .section-two-sub-child {
                // padding: 0 10%;

                .section-two-sub-child-parent-one {
                  width: 100%;
                  border-radius: 10px;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

                  .section-two-sub-child-one,
                  .section-two-sub-child-two,
                  .section-two-sub-child-three {
                    border: none;
                    height: 100%;
                  }

                  .section-two-sub-child-one {
                    border-radius: 10px 0 0 10px;
                    background-color: #d9d9d9;
                    padding: 50px 20px;
                  }

                  .section-two-sub-child-two {
                    background-color: white;
                    padding: 30% 0;

                    h5 {
                      color: #fdbf05;
                      font-weight: 700;
                    }

                    .para-one {
                      color: #8c8c89;
                    }

                    .para-two {
                      color: #8c8c89;
                    }
                  }

                  .section-two-sub-child-three {
                    background-color: white;
                    border-radius: 0px 10px 10px 0px;
                    padding: 10px 0;

                    .star-icon {
                      margin-bottom: 10px;
                      text-align: start;

                      i {
                        font-size: 20px;
                      }
                    }

                    .heading-sec {
                      text-align: start;

                      h6 {
                        font-weight: 700;
                      }
                    }

                    .para {
                      text-align: start;
                      padding-bottom: 10px;

                      p {
                        font-size: 70%;
                        color: #8c8c89;
                      }
                    }
                  }
                }
              }

              .show-more-btn {
                justify-content: center;
                align-items: center;
                display: flex;

                .btn {
                  background: #fdbf05;
                  font-weight: 700;

                  a {
                    color: #000;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-4 -->
    }
  }
}

//**** ----------------------- Start Tablet ------------------------- ****//
@media only screen and (min-width: 568px) and (max-width: 767px) {
  .product-details-page-banner-section {
    .screen-sixteen-nine-four-sec {

      //!-- Start Screen-16:9-4 Section-3 -->
      .screen-sixteen-nine-four-section-three {
        .section-one-parent-four {
          .section-one-parent-four-content-box-one {
            .icon-sec {
              i {
                margin-right: 10px;
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-3 -->

      //!-- Start Screen-16:9-4 Section-4 -->
      .screen-sixteen-nine-four-section-four {
        .section-two-parent-one {
          .heading-sec {
            text-align: center;

            h5 {
              font-weight: 700;
            }

            p {
              font-weight: 600;
            }
          }

          .section-two-child-sec {
            .section-two-child-one {
              .section-two-sub-child {
                .section-two-sub-child-parent-one {

                  .section-two-sub-child-one,
                  .section-two-sub-child-two,
                  .section-two-sub-child-three {
                    border: none;
                    height: 100%;
                  }

                  .section-two-sub-child-one {
                    border-radius: 10px 10px 0 0 !important;
                    background-color: #d9d9d9;
                    padding: 50px 20px;
                  }

                  .section-two-sub-child-two {
                    background-color: white;
                    padding: 20px 0;

                    h5 {
                      color: #fdbf05;
                      font-weight: 700;
                    }

                    .para-one {
                      color: #8c8c89;
                    }

                    .para-two {
                      color: #8c8c89;
                    }
                  }

                  .section-two-sub-child-three {
                    background-color: white;
                    border-radius: 0 0 10px 10px;
                    padding: 10px;

                    .heading-sec {
                      text-align: start;

                      h6 {
                        font-weight: 700;
                      }
                    }

                    .para {
                      text-align: start;
                      padding-bottom: 10px;

                      p {
                        font-size: 70%;
                        color: #8c8c89;
                      }
                    }
                  }
                }
              }

              .show-more-btn {
                justify-content: center;
                align-items: center;
                display: flex;

                .btn {
                  background: #fdbf05;
                  font-weight: 700;

                  a {
                    color: #000;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-4 -->
    }
  }
}

//**** ----------------------- End Tablet ------------------------- ****//

//**** ----------------------- Start Mobile ------------------------- ****//
@media only screen and (min-width: 200px) and (max-width: 567px) {
  .product-details-page-banner-section {
    .screen-sixteen-nine-four-sec {
      .screen-sixteen-nine-four-img-sec {
        position: fixed;

        img {
          height: 300px;
          width: 300px;
        }
      }

      //!-- Start Screen-16:9-4 Section-1 -->
      .screen-sixteen-nine-four-section-one {
        .section-one-parent-one {
          height: 320px;

          .card {
            // margin-left: 100px;
            // margin-right: 20px;
            // height: 300px;
            margin: 9% 0;
            background-color: #f2f2f0;
            border-radius: 10px;
            border: none;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .card-body {
              .img-sec {
                padding: 0 10%;

                img {
                  height: 200px;
                }
              }

              .slider-sec {
                position: relative;

                .card {
                  border: 1px solid #c1c1c1;
                  background-color: #fff;
                  box-shadow: none;
                  margin-right: 5px;
                  margin-bottom: 10px;

                  .card-body {
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .img-sec {
                      img {
                        height: 100%;
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .section-one-parent-two {
          height: 620px;

          .img-sec {
            padding-left: 180px;
          }

          .section-one-parent-two-child {
            .section-one-parent-two-sub-child {
              .heading-sec {
                h1 {
                  font-size: 32px;
                }

                p {
                  font-weight: 400;
                }
              }

              .para-sec {
                padding-right: 30%;

                p {
                  font-size: 14px;
                }
              }

              .keyfeatures-sec {
                p {
                  font-weight: 400;
                  font-size: 15px;
                }

                ul li {
                  font-size: 14px;
                }
              }

              .btns {
                display: block;

                .btn-one {
                  width: 100%;
                  font-size: 14px;
                  padding: 1% 5%;
                  // margin-left: 12%;
                  margin: 5px 0px;

                  img {
                    height: 25px;
                  }
                }

                .btn-two {
                  width: 100%;
                  font-size: 14px;
                  padding: 1% 5%;
                  // margin-left: 12%;
                  margin: 5px 0px;

                  img {
                    height: 25px;
                  }
                }

                .btn-three {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                  border-radius: 8px;
                  border: none;
                  background-color: #fdbf05;
                  width: 100%;
                  font-size: 14px;
                  margin: 5px 0px;
                  // padding: 1% 5%;
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  // margin-left: 12%;

                  .btn-three-img-sec {
                    position: relative;

                    img {
                      height: 25px;
                    }

                    .font-icon {
                      position: absolute;
                      top: 20%;
                      left: 20%;
                      font-size: 80%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-1 -->

      //!-- Start Screen-16:9-4 Section-2 -->
      .screen-sixteen-nine-four-section-two {
        .section-one-parent-three {
          position: relative;

          .nav.nav-tabs {
            justify-content: center;
            align-items: center;
            display: flex;
            border: none;

            .nav-item {
              .nav-link.active {
                color: #000;
                font-weight: 700;
                border: none;
                border-bottom: 1px solid !important;
              }

              .nav-link {
                color: #000;
                border: none;
              }
            }
          }

          .tab-content {
            .tab-pane {
              table {
                border: 1px solid #bbbbb7;
                // width: 100%;
                margin-top: 30px;

                .t-heading {
                  th {
                    padding-top: 30px;
                    // width: 100%;
                  }
                }

                tr {
                  border-bottom: 1px solid #bbbbb7;
                }
              }
            }
          }

          .screen-sixteen-nine-four-section-two-img-sec {
            position: fixed;
            bottom: 0;
            left: 0;

            img {
              width: 300px;
              height: 300px;
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-2 -->

      //!-- Start Screen-16:9-4 Section-3 -->
      .screen-sixteen-nine-four-section-three {
        .section-one-parent-four {
          .heading {
            justify-content: center;
            align-items: center;
            display: flex;

            h5 {
              font-weight: 700;
            }
          }

          .section-one-parent-four-content-box-one {
            padding: 40px 20px;
            border: 1px solid;
            text-align: center;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .icon-sec {
              i {
                margin-right: 10px;
              }
            }
          }

          .section-one-parent-four-content-box-two {
            border: 1px solid;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

            .progress-one,
            .progress-two,
            .progress-three,
            .progress-four,
            .progress-five {
              display: flex;

              .number-five,
              .percent {
                font-size: 13px;
                width: 30px;
                color: #8c8c89;
              }

              .star-icon {
                font-size: 13px;
                width: 50px;
                color: #fdbf05;
              }

              .progress {
                height: 14px;
                width: 600px;
                margin-top: 0.5%;

                .progress-bar {
                  height: 20px;
                }
              }

              .percent {
                padding-left: 20px;
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-3 -->

      //!-- Start Screen-16:9-4 Section-4 -->
      .screen-sixteen-nine-four-section-four {
        .section-two-parent-one {
          .heading-sec {
            text-align: center;

            h5 {
              font-weight: 700;
            }

            p {
              font-weight: 600;
            }
          }

          .section-two-child-sec {
            .section-two-child-one {
              .section-two-sub-child {
                .section-two-sub-child-parent-one {

                  .section-two-sub-child-one,
                  .section-two-sub-child-two,
                  .section-two-sub-child-three {
                    border: none;
                    height: 100%;
                  }

                  .section-two-sub-child-one {
                    border-radius: 10px 10px 0 0 !important;
                    background-color: #d9d9d9;
                    padding: 50px 20px;
                  }

                  .section-two-sub-child-two {
                    background-color: white;
                    padding: 20px 0;

                    h5 {
                      color: #fdbf05;
                      font-weight: 700;
                    }

                    .para-one {
                      color: #8c8c89;
                    }

                    .para-two {
                      color: #8c8c89;
                    }
                  }

                  .section-two-sub-child-three {
                    background-color: white;
                    border-radius: 0 0 10px 10px;
                    padding: 10px;

                    .heading-sec {
                      text-align: start;

                      h6 {
                        font-weight: 700;
                      }
                    }

                    .para {
                      text-align: start;
                      padding-bottom: 10px;

                      p {
                        font-size: 70%;
                        color: #8c8c89;
                      }
                    }
                  }
                }
              }

              .show-more-btn {
                justify-content: center;
                align-items: center;
                display: flex;

                .btn {
                  background: #fdbf05;
                  font-weight: 700;

                  a {
                    color: #000;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }

      //!-- End Screen-16:9-4 Section-4 -->
    }
  }
}

//**** ----------------------- Start Mobile ------------------------- ****//