.our-journey-component-section{
    background: #cccccc5c;
    background-image: url("../../../../public/images/about-us/color-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px 0px 0px;

    .our-journey-heading-dv {
        h5 {
            font-weight: 700;
            font-size: 22px;
        }
    }
    .journey-images-parent{
        margin: 20px 0px 0px 0px;
    }
}