.help-and-support-section {
    background: #cccccc5c;
    background-image: url("../../../../public/images/help-support-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;

    .heading-dv {
        h3 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .help-support-inner-section {
        .first-card-parent {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px 20px;
            margin: 20px 0px;

            .mobile-tag {
                text-align: left;

                p {
                    margin-bottom: 0px;
                    margin: 10px 0px;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .second-side-card-parent {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 5px 20px;
            margin: 8px 0px;

            .mobile-tag {
                text-align: left;

                p {
                    margin-bottom: 0px;
                    margin: 10px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: space-between;
                    align-items: center;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}