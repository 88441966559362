.pillar-videos-details-banner-section {
  background: #000;
  // background-image: url("../../../../../../public/images/blog/blog-detail-banner.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 80px 0px;
  height: 450px;
}
