.contact-us-content-section {
  background: #000;
  background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-attachment: fixed;
}
.contact-us-content-section .contact-parent-card {
  margin-bottom: 30px;
}
.contact-us-content-section .contact-parent-card .left-contact-card {
  border-radius: 10px;
  background: #F2F1F1;
  padding: 30px;
  height: 100%;
}
.contact-us-content-section .contact-parent-card .left-contact-card .image-heading h3 {
  font-size: 22px;
  font-weight: 700;
  padding: 20px;
}
.contact-us-content-section .contact-parent-card .left-contact-card .corporate-address-parent .corporate-office-address h3 {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
}
.contact-us-content-section .contact-parent-card .left-contact-card .corporate-address-parent .corporate-office-address p {
  text-align: left;
}
.contact-us-content-section .contact-parent-card .left-contact-card .corporate-address-parent .get-direction-btn .submit-btn-custom {
  background-color: #000;
  color: #FFBC00;
}
.contact-us-content-section .contact-parent-card .left-contact-card .industries-address-parent {
  margin-top: 30px;
}
.contact-us-content-section .contact-parent-card .left-contact-card .industries-address-parent .corporate-office-address h3 {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
}
.contact-us-content-section .contact-parent-card .left-contact-card .industries-address-parent .corporate-office-address p {
  text-align: left;
}
.contact-us-content-section .contact-parent-card .left-contact-card .industries-address-parent .get-direction-btn .submit-btn-custom {
  background-color: #000;
  color: #FFBC00;
}
.contact-us-content-section .contact-parent-card .right-contact-card {
  border-radius: 10px;
  background: #F2F1F1;
  padding: 30px;
  height: calc(50% - 12px);
}
.contact-us-content-section .contact-parent-card .right-contact-card .image-heading h3 {
  font-size: 22px;
  font-weight: 700;
  padding: 20px;
}
.contact-us-content-section .contact-parent-card .right-contact-card .image-heading a {
  text-decoration: none;
  color: #000;
}
.contact-us-content-section .contact-parent-card .right-contact-card .image-heading1 h3 {
  font-size: 22px;
  font-weight: 700;
  padding: 20px;
}
.contact-us-content-section .contact-parent-card .right-contact-card .image-heading1 a {
  text-decoration: none;
  color: #000;
}
.contact-us-content-section .help-support-heading h5 {
  font-weight: 700;
}
.contact-us-content-section .help-support-content-dv {
  margin: 20px 0px;
}
.contact-us-content-section .form-parent-custon-dv {
  text-align: left;
  border-radius: 16px;
  background: #F1F0F0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form {
  text-align: left;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form input::-moz-placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form input::placeholder {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form select {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-front {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-front .upload-product-icon {
  position: absolute;
  right: 6px;
  top: 4px;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-front .upload-product-icon img {
  width: 25px;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-front span {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-back {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-back .upload-product-icon {
  position: absolute;
  right: 6px;
  top: 4px;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-back .upload-product-icon img {
  width: 25px;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .upload-product-back span {
  font-size: 13px;
  color: #818181;
  font-style: italic;
}
.contact-us-content-section .form-parent-custon-dv .custom-row-form .submit-btn-custom {
  background-color: #000;
  color: #FFBC00;
}
.contact-us-content-section .map-two-div {
  padding-top: 40px;
}
.contact-us-content-section .map-two-div .help-support-heading h5 {
  font-weight: 700;
}
.contact-us-content-section .map-two-div .map-dv-parent h5 {
  font-weight: 600;
}
.contact-us-content-section .map-two-div .map-dv-parent iframe {
  border: 10px solid #ccc !important;
}
.contact-us-content-section .react-international-phone-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
}
.contact-us-content-section .react-international-phone-country-selector-button {
  border: none;
}/*# sourceMappingURL=ContactUsPageNewContent.css.map */