.vision-mission-value-section {
  background: #000;
  background-image: url("../../../../public/images/about-us/color-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0px;
}
.vision-mission-value-section .vision-mission-value-dv {
  padding: 20px;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-heading h4 {
  font-size: 22px;
  font-weight: 700;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-heading h4 span {
  color: #ffc300;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-content {
  text-align: center;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-content p {
  height: 180px;
  overflow-y: scroll;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-content p::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-content p::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.vision-mission-value-section .vision-mission-value-dv .v-m-v-content p::-webkit-scrollbar-thumb {
  background-color: #000000;
}/*# sourceMappingURL=VisionMissionSec.css.map */