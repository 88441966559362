.contact-us-page-banner-section {
  background: #000;
  background-image: url("../../../../public/images/contact-us/banner-bg.png");
  background-position: center;
  background-size: 100% 100%;
  background-color: #F1F0F0;
  background-repeat: no-repeat;
  padding: 20px 0px;
}
.contact-us-page-banner-section .banner-images-left img {
  width: 90%;
}
.contact-us-page-banner-section .business-heading-top h5 {
  font-weight: 400;
  font-size: 20px;
  text-align: left;
}
.contact-us-page-banner-section .business-heading {
  position: relative;
  text-align: center;
}
.contact-us-page-banner-section .business-heading h1 {
  font-weight: 700;
  font-size: 50px;
  text-align: left;
}/*# sourceMappingURL=ContactUsPageBanner.css.map */