@media only screen and (min-width: 768px) and (max-width: 992px) {
  .store-locator-banner-section {
    background: #000;
    background-position: center;
    background-size: cover;
    background-color: #f1f0f0;
    background-repeat: no-repeat;
    padding: 50px 0px;
  }
  .store-locator-banner-section .store-heading {
    position: relative;
    text-align: center;
  }
  .store-locator-banner-section .store-heading img {
    height: 60px;
  }
  .store-locator-banner-section .store-heading h5 {
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .store-locator-banner-section .banner-img-dv {
    width: 100%;
    text-align: center;
    padding: 30px 0px;
  }
  .store-locator-banner-section .banner-img-dv img {
    width: 450px;
  }
  .store-locator-banner-section .banner-dv-search .banner-search-parent input {
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: none;
    padding: 5px 20px;
    margin-right: 20px;
    width: 270px;
  }
  .store-locator-banner-section .banner-dv-search .banner-search-parent input[placeholder] {
    font-size: 14px;
  }
  .store-locator-banner-section .banner-dv-search .banner-search-parent .banner-search-btn {
    box-shadow: none;
    background-color: #000;
    color: #ffc300;
    border: 1px solid #ccc;
    padding: 5px 20px;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .store-locator-banner-section {
    background: #000;
    background-position: center;
    background-size: cover;
    background-color: #f1f0f0;
    background-repeat: no-repeat;
    padding: 50px 0px;
  }
  .store-locator-banner-section .store-heading {
    position: relative;
    text-align: center;
  }
  .store-locator-banner-section .store-heading img {
    height: 60px;
    width: 100%;
  }
  .store-locator-banner-section .store-heading h5 {
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .store-locator-banner-section .banner-img-dv {
    width: 100%;
    text-align: center;
    padding: 30px 0px;
  }
  .store-locator-banner-section .banner-img-dv img {
    width: 450px;
  }
  .store-locator-banner-section .banner-dv-search .banner-search-parent input {
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: none;
    padding: 5px 20px;
    margin-right: 20px;
    width: 270px;
  }
  .store-locator-banner-section .banner-dv-search .banner-search-parent input[placeholder] {
    font-size: 14px;
  }
  .store-locator-banner-section .banner-dv-search .banner-search-parent .banner-search-btn {
    box-shadow: none;
    background-color: #000;
    color: #ffc300;
    border: 1px solid #ccc;
    padding: 5px 20px;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 405px) {
  .store-locator-banner-section .banner-dv-search .banner-search-parent input {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}/*# sourceMappingURL=ResponsiveStoreLocatorBanner.css.map */