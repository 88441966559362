.global-footprint-banner-section {
  background: #000;
  background-image: url("../../../../public/images/global-footprints/banner-bg.jpg");
  background-position: center;
  background-size: cover;
  background-color: #f1f0f0;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.global-footprint-banner-section .store-heading {
  position: relative;
  text-align: center;
}
.global-footprint-banner-section .store-heading img {
  height: 60px;
}
.global-footprint-banner-section .store-heading h5 {
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.global-footprint-banner-section .banner-img-dv {
  width: 100%;
  text-align: center;
  padding: 30px 0px;
}
.global-footprint-banner-section .banner-img-dv img {
  width: 600px;
  height: 300px;
}/*# sourceMappingURL=GlobalFootprintsBanner.css.map */